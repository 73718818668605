@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,700,800|PT+Sans:400,700,400italic|Exo+2:400,300,500,600,700,800&subset=latin,cyrillic);

$red: #e31e24;

body{
    background-color: #232323;
    margin: 0;
    font-family: PT Sans;
    font-size: 16px;
    line-height: 1.5;
}
#page {
    background-color: #fff;
    max-width: 100%;
}

.content-inner #main-content #content{
    background-color: #f7f7f7;
}
/*Header*/
#header{
    background-color: #fff;
    box-shadow: 0 2px 5px -2px #000;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    .region-header{
        margin: 0 auto;
        width: 1140px;
    }
}
#columns{
    padding: 80px 0 0;
}
#block-superfish-4{
    background-color: #efefef;
    box-shadow: 0 1px 3px -1px #777;
    position: fixed;
    top: -60px;
    transition: all 0.2s ease 0s;
    width: 100%;
    z-index: 999;
    &.animated-menu{
        top: 0;
    }
    .block-inner{
        margin: 0 auto;
        width: 1140px;
    }
    .animated-menu-image{
        display: inline-block;
        height: 40px;
        margin: 0 20px 0 0;
        padding: 5px 0;
        vertical-align: top;
        width: 70px;
        img{
            display: block;
            height: auto;
            max-height: 100%;
            max-width: 100%;
        }
    }
    .animated-menu-title{
        display: inline-block;
        height: 50px;
        margin: 0 20px 0 0;
        vertical-align: top;
        a{
            color: #000;
            display: block;
            font-family: Open Sans;
            font-size: 32px;
            font-weight: 100;
            letter-spacing: 0;
            line-height: 1;
            padding: 9px 0;
            text-decoration: none;
            text-transform: uppercase;
        }
    }
    ul.menu{
        display: inline-block;
        float: right;
        height: 50px;
        vertical-align: top;
        li{
            display: table-cell;
            float: none;
            margin: 0;
            a{
                color: #000;
                display: block;
                font-family: Open Sans;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 0.5px;
                line-height: 1;
                padding: 18px 15px;
                text-align: center;
                text-decoration: none;
                transition: all 0.2s ease 0s;
                &.active{
                    background-color: #afb42b;
                    color: #fff;
                }
                &:hover{
                    background-color: #afb42b;
                    color: #fff;
                    opacity: 0.8;
                }
            }
            & > ul{
                background-color: #efefef;
                box-shadow: 0 3px 5px -3px #444;
                margin: 8px 0 0;
                li{
                    border-top: 1px dashed #ccc;
                    display: block;
                }
            }
            &.sfHover > a{
                background-color: #afb42b;
                color: #fff;
            }
        }
    }
}
#block-block-40{
    float: left;
    margin: 0;
    width: 25%;
    .content{
        display: table-cell;
        height: 70px;
        vertical-align: middle;
    }
    p{
        float: left;
        margin: 0 20px 0 0;
    }
    img{
        display: block;
        height: auto;
        position: relative;
        width: 100px;
        z-index: 1;
    }
    h2{
        line-height: 1;
        margin: 0 0 -5px;
        a{
            color: #cd2929;
            font-family: Open Sans;
            font-size: 26px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 1;
            margin: 0;
            text-decoration: none;
        }
    }
    h3{
        line-height: 1;
        margin: 0;
        a{
            color: #000;
            font-family: Open Sans;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.5px;
            line-height: 1;
            margin: 0;
            text-decoration: none;
        }
    }

}
#block-block-41{
    margin: 0 auto;
    width: 1140px;
    .block-inner{
        float: right;
        height: 30px;
        margin: -70px 0 0;
    }
    .socseti{
        & > div{
            display: inline-block;
            margin: 0;
            a{
                background-color: #232323;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 60% auto;
                display: block;
                height: 35px;
                text-indent: -9999px;
                width: 35px;
                &:hover{
                    background-color: #afb42b;
                }
            }
        }
        .set1 a{
            background-image: url("../images/icons/facebook.png");
        }
        .set2 a{
            background-image: url("../images/icons/vk.png");
        }
        .set3 a{
            background-image: url("../images/icons/ok.png");
        }
        .set4 a{
            background-image: url("../images/icons/twitter.png");
        }
        .set5 a{
            background-image: url("../images/icons/insta.png");
        }
    }
}
#block-block-42{
    margin: 0 auto;
    width: 1140px;
    .block-inner{
        float: right;
        height: 70px;
        margin: -70px 380px 0 0;
        position: relative;
        z-index: 1;
    }
    h2{
        font-family: Open Sans;
        font-size: 40px;
        font-weight: 100;
        letter-spacing: 0.5px;
        line-height: 1;
        margin: 5px 0;
    }
    h3{
        line-height: 1;
        margin: 0;
        a{
            color: #afb42b;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.5px;
            &:hover{
                text-decoration: none;
            }
        }
    }
}
#views-exposed-form-search-page{
    margin: 0 auto;
    width: 1140px;
    & > div {
        margin: -30px 0 0;
        position: absolute;
        width: 1140px;
    }
    .views-exposed-form{
        float: right;
    }
    .views-exposed-form .views-exposed-widget {
        padding: 0;
    }
    #edit-combine-wrapper{
        float: left;
        padding: 0;
        label{
            display: none;
        }
        input#edit-combine{
            background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
            border: 1px solid #ccc;
            color: #232323;
            font-family: PT Sans;
            font-size: 14px;
            letter-spacing: 0.5px;
            line-height: 1;
            margin: 0;
            padding: 7px 10px;
            width: 172px;
        }
    }
    .views-submit-button{
        float: right !important;
        input{
            background-color: transparent ;
            background-image: url("../images/elements/loop.png");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 60% auto;
            border: medium none;
            cursor: pointer;
            display: block;
            height: 28px;
            margin: 1px 0 0 -29px;
            position: absolute;
            text-indent: -9999px;
            width: 28px;
            &:hover{
                opacity: 0.8;
            }
        }
    }
    .throbber{
        display: none !important;
    }
}
#block-superfish-1{
    width: 70%;
    float: right;

    .block-inner{
        margin: 0 auto;
        width: 100%;
    }
}
#superfish-1{
    display: table;
    margin: 0;
    width: 100%;
    li{
        display: table-cell;
        float: none;
        margin: 0;
        a{
            color: #2e2e2e;
            display: block;
            font-family: PT Sans;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 1px;
            line-height: 1;
            padding: 40px 0;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            transition: all 0.2s ease 0s;
            &.active{
                text-decoration: underline;
            }
            &:hover{
                text-decoration: underline;
                opacity: 0.8;
            }
        }
        & > ul{
            background-color: #efefef;
            box-shadow: 0 3px 5px -3px #444;
            margin: 8px 0 0;
            li{
                border-top: 1px dashed #ccc;
                display: block;
            }
        }

    }
}
#menu-bar{
    margin: 0;
}
#block-views-slider-block{
    h2{
        display: none;
    }
}
#flexslider-1{
    border: medium none;
    border-radius: 0;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    ul.slides{
        margin: 0;
        li{
            margin: 0;
        }
    }
    .views-field-field-image-slide{
        max-height: 500px;
    }
    .views-field-nid{
        margin: 0 auto;
        width: 1000px;
        .field-content{
            background-color: rgba(0, 0, 0, 0.7);
            display: block;
            margin: 50px 0 0;
            padding: 20px;
            position: absolute;
            width: 940px;
            .wrap{
                border: 2px solid #fff;
                padding: 40px;
            }
        }
    }
    .title{
        color: #afb42b;
        font-family: Open Sans;
        font-size: 52px;
        font-weight: 800;
        letter-spacing: 0.5px;
        line-height: 1;
        margin: 0 0 20px;
    }
    .body{
        color: #fff;
        font-family: PT Sans;
        font-size: 24px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1.5;
        margin: 0 0 30px;
        p{
            margin: 0;
        }
    }
    .ssilka{
        margin: 10px 0;
        a{
            border: 1px solid #fff;
            color: #fff;
            font-family: Open Sans;
            font-size: 20px;
            font-weight: 100;
            letter-spacing: 0.7px;
            margin: 0;
            padding: 7px 30px;
            text-decoration: none;
            &:hover{
                background-color: #afb42b;
                border-color: #afb42b;
                color: #fff;
            }
        }
    }
    ul.flex-direction-nav{
        margin: 0;
        li{
            margin: 0;
            a{
                background-color: rgba(0, 0, 0, 0.8);
                background-image: url("../images/elements/arrows-big.png");
                background-repeat: no-repeat;
                display: block !important;
                height: 20%;
                margin: 0;
                opacity: 1 !important;
                text-indent: -9999px;
                top: 40%;
                width: 50px;
                &:hover{
                    opacity: 0.8 !important;
                }
            }
            &.flex-nav-prev{
                a{
                    background-image: url("../images/elements/arrow-left.png");
                    background-position: center center;
                    background-size: 60% auto;
                }
            }
            &.flex-nav-next{
                a{
                    background-image: url("../images/elements/arrow-right.png");
                    background-position: center center;
                    background-size: 60% auto;
                    text-indent: 9999px;
                }
            }
        }
    }
}
#block-block-43{
    background-color: #efefef;
    padding: 70px 0;
    h2{
        font-family: Open Sans;
        font-size: 54px;
        font-weight: 100;
        letter-spacing: 2px;
        line-height: 1.2;
        margin: 0 auto 20px;
        text-align: center;
        text-transform: uppercase;
        width: 1140px;
    }
    p{
        font-family: Open Sans;
        font-size: 20px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1.7;
        margin: 0 auto;
        text-align: center;
        width: 800px;
    }
}
.not-front {
    #block-block-62 {
        p {
            color: #fff;
            a {
                color: #cd2929;
            }
        }
    }
}
#block-block-62 {
    top: 55px;
    width: 198px;
    position: absolute;
    p {
        margin: 0px;
        text-align: left;
        font-family: "Exo 2";
    }
}
/* End of header*/

/* Main page */

#block-block-44{
    padding: 50px 0;
    h2{
        font-family: Open Sans;
        font-size: 44px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        width: 1140px;
    }
    table{
        margin: 0 auto;
        width: 1140px;
        tbody{
            border: none;
        }
        td{
            padding: 0 40px 0 60px;
            vertical-align: top;
            &:nth-child(1) h3::before{
                content: "1";
            }
            &:nth-child(2) h3::before{
                content: "2";
            }
            &:nth-child(3) h3::before{
                content: "3";
            }
            &:nth-child(4) h3::before{
                content: "4";
            }
        }
        h3{
            font-family: Open Sans;
            font-size: 18px;
            font-weight: 800;
            letter-spacing: 0.5px;
            line-height: 1.3;
            margin: 0 0 10px;
            &::before {
                border: 1px solid;
                border-radius: 40px;
                color: #afb42b;
                display: block;
                font-size: 24px;
                font-weight: 300;
                height: 25px;
                line-height: 1;
                margin: 0 0 0 -75px;
                padding: 18px 0;
                position: absolute;
                text-align: center;
                width: 60px;
            }
        }
        p{
            font-size: 14px;
            letter-spacing: 0.5px;
            line-height: 1.5;
            margin: 0;
        }
    }
}
#block-block-45{
    background-color: #efefef;
    padding: 50px 0;
    h2{
        font-family: Open Sans;
        font-size: 40px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1.3;
        margin: 0 auto 10px;
        text-align: center;
        text-transform: uppercase;
        width: 1140px;
    }
    h3{
        font-family: Open Sans;
        font-size: 20px;
        font-weight: 100;
        letter-spacing: 1px;
        margin: 0 auto 10px;
        text-align: center;
        width: 1140px;
    }
    p{
        font-family: Open Sans;
        font-size: 20px;
        font-weight: 100;
        letter-spacing: 1px;
        margin: 40px auto 10px;
        text-align: center;
        width: 1140px;
        a{
            border: 2px solid #afb42b;
            color: #afb42b;
            font-family: PT Sans;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.7px;
            line-height: 1;
            margin: 0;
            padding: 8px 30px;
            text-decoration: none;
            &:hover{
                background-color: #afb42b;
                color: #fff;
            }
        }
    }
}
#mba-seminary {
    width: 100%;
    margin: 0 auto;
    background-attachment: fixed;
    background-image: url("../images/bg-52.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0;
    color:white;
    text-align:center;
    #block-block-52{
        display: inline-block;
        vertical-align: top;
        width: 565px;
        .title{
            font-family: "Exo 2";
            margin: 0 auto 100px;
            text-align: center;
            float: left;
            .tit_1{
                font-size: 16px;
                letter-spacing: 1px;
                margin: 0;
            }
            .tit_2{
                font-size: 44px;
                font-weight: 800;
                letter-spacing: 7px;
                line-height: 1;
                margin: 0;
            }
        }
        .content{
            font-family: "Exo 2";
            margin: 0 auto;
            .item{
                display: inline-block;
                line-height: 1;
                text-align: left;
                width: 34%;
                vertical-align: top;
                margin: 0 90px 40px 0px;
                h4{
                    border-bottom: 4px solid #dc3326;
                    font-size: 44px;
                    margin: 0;
                    padding: 0 0 7px;
                }
                p{
                    font-size: 20px;
                    line-height: 1;
                    margin: 0;
                    padding: 5px 0 0;
                }
            }
        }
    }
    #seminar-kar {
        display: inline-block;
        vertical-align: top;
        width: 525px;
        margin: 0px 0px 0px 10px;
        background-color: RGBA(35, 35, 35, 0.48);
        padding: 20px 40px;
        .views-field-nid {
            margin: 20px 0;
            a {
                border: 1px solid #cd2929;
                color: #cd2929;
                font-family: PT Sans;
                font-size: 14px;
                font-weight: normal;
                letter-spacing: 1px;
                line-height: 1;
                margin: 0;
                padding: 10px 35px;
                text-decoration: none;
                &:hover{
                    background-color: #cd2929;
                    color: #fff;
                }
            }
        }
    }
    #block-views-seminary-block-4 {
        h2 {
            margin: 15px 0px;
            font-size: 24px;
            top: 0;
            right: 0;
        }
        .view-content {
            background: none;
            border: none;
            text-align: left;
            &:hover{
                box-shadow: none;
            }
            .views-field-title{
                font-size: 30px;
                font-family: "Exo 2";
                line-height: 1;
                font-weight: 600;
            }
            .views-field-body {
                margin: 25px 0px 50px;
                p {
                    text-align: left;
                    line-height: 1.2;
                }
                strong {
                    font-weight: 400;
                }
            }
            .views-field-field-data-seminar {
                border-bottom: 4px solid #dc3326;
                font-size: 44px;
                margin: 0 0 5px 0;
                width: 200px;
                font-weight: bold;
                line-height: 1;
                padding: 0 0 5px 0px;
            }
            .views-field-nid{
                height: 35px;
                a{
                    background-color: #cd2929;
                    border: 1px solid #cd2929;
                    color: #fff;
                    font-size: 14px;
                    letter-spacing: 0.7px;
                    line-height: 1;
                    padding: 7px 20px;
                    text-decoration: none;
                    &:hover{
                        background-color: #fff;
                        color: #cd2929;
                    }
                }
            }            
        }
        .slick-list{
            margin: 0;
            width: 100%;
        }
        .slide__content{
            background: none;
            width: 520px;
            border: none;
            text-align: left;
            &:hover{
                box-shadow: none;
            }
            .views-field-title{
                font-size: 30px;
                font-family: "Exo 2";
                // width: 580px;
                line-height: 1;
            }
            .views-field-body {
                margin: 25px 0px 0px;
            }
            .views-field-field-data-seminar {
                border-bottom: 4px solid #dc3326;
                font-size: 44px;
                margin: 0 0 5px 0;
                width: 200px;
                font-weight: bold;
                line-height: 1;
                padding: 0 0 5px 0px;
            }
            .views-field-nid{
                height: 35px;
                a{
                    background-color: #cd2929;
                    border: 1px solid #cd2929;
                    color: #fff;
                    font-size: 14px;
                    letter-spacing: 0.7px;
                    line-height: 1;
                    padding: 7px 20px;
                    text-decoration: none;
                    &:hover{
                        background-color: #fff;
                        color: #cd2929;
                    }
                }
            }
        }
        .slick__arrow {
            top: 40%;
            .slick-arrow {
                &::before {
                    color: #cd2929;
                    font-size: 20px;
                }
            }
            .slick-prev {
                left: -40px;
            }
            .slick-next {
                right: -40px;
            }
        }
    }
    #block-views-seminary-block-2 {
        h2 {
            font-family: "Exo 2";
            font-size: 44px;
            margin: 0 auto 22px;
        }
        .slick__slider{
            .slick-list{
                padding: 0 !important;
                .slick-track {
                    padding: 0px;
                }
            }
            .slide__content{
                background-color: transparent;
                border: medium none;
                border-radius: 0;
                margin: 0;
                padding: 10px 30px 22px 0;
                transition: all 0s ease 0s;
                text-align: left;
                width: 220px;
                &:hover{
                    box-shadow: none;
                }
            }
            .views-field-field-data-seminar{
                font-family: "Exo 2";
                font-size: 36px;
                letter-spacing: 0px;
                line-height: 1;
                margin: 0 0 10px;
                span{
                    border-bottom: 4px solid #DC3326;
                }
            }
            .views-field-title{
                font-family: Open Sans;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 0.5px;
                line-height: 1.3;
                margin: 0 0 20px;
            }
            .views-field-body{
                font-family: Open Sans;
                font-size: 14px;
                letter-spacing: 0.5px;
                line-height: 1.4;
                margin: 0 0 20px;
                p{
                    margin: 0;
                }
            }
        }
        .slick__arrow{
            opacity: 1;
            button{
                background-color: #444;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 40% auto;
                border-radius: 0;
                height: 80px;
                text-indent: -9999px;
                width: 40px;
                &:hover{
                    background-color: #dc3326;
                }
            }
            .slick-prev{
                background-image: url("../images/elements/arrow-left.png");
            }
            .slick-next{
                background-image: url("../images/elements/arrow-right.png");
            }
        }
        .more-link{
            text-align: center;
            a{
                background-color: #cd2929;
                border: medium none;
                color: #fff;
                cursor: pointer;
                font-family: Open Sans;
                font-size: 16px;
                font-weight: 100;
                letter-spacing: 0.5px;
                line-height: 40px;
                margin: 0;
                padding: 12px 35px;
                text-decoration: none;
                text-transform: uppercase;
                &:hover{
                    box-shadow: 0 1px 3px -1px #000;
                }
            }
        }
    }
}
#block-block-53{
    padding: 40px 0;
    height: 360px;
    background-color: #fff;
    #block53_1 {
        width: 1140px;
        margin: 0 auto;
        .title {
            float: left;
            margin-top: 70px;
            width: 100%;
            margin: 0 0 30px 0;
            img{
                float: left;
                margin-right: 40px;
            }
            p.rus{
                border-bottom: 1px solid #ddd;
                border-top: 1px solid #ddd;
                font-family: Open Sans;
                font-size: 26px;
                font-weight: 700;
                line-height: 2;
                margin: 20px 0 0;
                text-transform: uppercase;
            }
            p.eng{
                border-bottom: 1px solid #ddd;
                color: #cd2929;
                font-family: Open Sans;
                font-size: 18px;
                letter-spacing: 1px;
                line-height: 1.7;
                margin: 0;
            }
        }
        .content {
            float: left;
            font-family: Open Sans;
            font-size: 14px;

            line-height: 2;
            .more_link {
                margin: 20px 0 0;
                a{
                    border: 1px solid #cd2929;
                    color: #cd2929;
                    font-family: PT Sans;
                    font-size: 14px;
                    font-weight: normal;
                    letter-spacing: 1px;
                    line-height: 1;
                    margin: 0;
                    padding: 10px 35px;
                    text-decoration: none;
                    &:hover{
                        background-color: #cd2929;
                        color: #fff;
                    }
                }
            }
        }
    }
}

div#karusel {
    width: 1140px;
    margin: 0 auto;
    top: -250px;
    background-color: #efefef;
}
#block-views-prepodavateli-block{
    background-color: #fff;
    padding-bottom: 20px;
    position: relative;
    height: 230px;
    #karusel-slider > .slick-list {
        padding: 0!important; 
        cursor: pointer;
    }
    .slick__arrow{
        width: calc(100% - 2%);
        left: 1%;
        .slick-arrow{
            background-color: #444;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 40% auto;
            border-radius: 0;
            height: 80px;
            text-indent: -9999px;
            width: 40px;
            &:hover{
                opacity: 0.8;
            }
            &.slick-prev{
                background-image: url("../images/elements/arrow-left.png");
            }
            &.slick-next{
                background-image: url("../images/elements/arrow-right.png");
            }
        }
    }
    .views-field.views-field-title {
        text-align: center;
        padding: 10px 10px 5px;
        .field-content {
            color: #4d4d4d;
            font-size: 14px;
            text-align: center;
        }
        span.field-content {
            color: black;
            display: block;
            font-family: Open Sans;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.3;
            text-align: center;
        }
    }
    .views-field-field-dolzhnost{
        font-family: Open Sans;
        font-size: 14px;

        padding: 0 10px;
        text-align: center;
    }
    #karusel-slider{

        .draggable{
            .slick-current {
                .slide__content {
                    border: 5px solid $red !important;
                }
            }
            .slick-track{
                padding-bottom: 0px!important;
                padding-top: 0px!important;
                .slide__content{
                    background-color: #efefef;
                    border: medium none;
                    border-radius: 0;
                    margin: 30px 0;
                    padding: 15px;
                    height: 100%;
                    &:hover{
                        background-color: #fff;
                        box-shadow: none;
                    }
                    .views-field-field-izobrazhenie-prepodavatel{
                        img {
                            box-shadow: 0px 4px 15px rgba(122, 122, 122, 0.6);
                        }
                    }
                }
            }
        }
    }
}
div#block-views-prepodavateli-block-1 {
    width: 100%;
    margin: 0 auto;
    background-attachment: fixed;
    background-image: url("../images/bg_block1.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 30px;
    padding-bottom: 10px;
    color: white;
    overflow: hidden;
    padding-bottom: 250px;
    .view-header{
        height: 100px;
        .title{
            float: right;
            font-family: Open Sans;
            line-height: 1;
            width: 45%;
            img{
                float: right;
                margin-left: 25px;
            }
            p.rus {
                border-bottom: 1px solid #999;
                border-top: 1px solid #999;
                font-size: 18px;
                font-weight: 600;
                letter-spacing: 1px;
                line-height: 2;
                margin: 30px 0 0;
                text-align: right;
                text-transform: uppercase;
            }
            p.eng {
                border-bottom: 1px solid #999;
                color: #cd2929;
                font-size: 16px;
                letter-spacing: 1px;
                line-height: 1.7;
                margin: 0;
                text-align: right;
            }
        }
    }
    .view-id-prepodavateli{
        width: 1140px;
        margin: 0 auto;
        .slick-list {
            overflow: visible;
            .slick__slide {
                .slide__content{
                    height: 300px;
                }
            }
        }
        .views-field-field-izobrazhenie-prepodavatel{
            position: absolute;
            height: 360px;
            bottom: -10px;
            .field-content{
                img.image-style-medium{
                    margin-top: 24%;
                }
                img {
                    display: block;
                    width: 100%;
                    height: auto;
                    border: 8px solid #f7f7f7;
                    box-shadow: 0 0 7px #666;
                }
            }
        }
        .views-field-body{
            .field-content{
                float: right;
                font-family: Open Sans;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 0.7px;
                line-height: 1.7;
                margin-top: 20px;
                text-align: left;
                width: 70%;
            }
        }
        .views-field-title{
            span.field-content {
                float: right;
                font-family: Open Sans;
                font-size: 55px;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.7px;
                line-height: 1.2;
                margin: 30px 0 10px;
                text-align: left;
                width: 70%;
            }
            a{
                color: #fff;
                text-decoration: none;
                &:hover{
                    border-bottom: 1px solid;
                }
            }
        }
        .views-field-field-dolzhnost{
            .field-content {
                float: right;
                font-size: 20px;
                font-weight: 600;
                letter-spacing: 0.7px;
                width: 70%;
                text-align: left;
            }
        }
    }

    .view-content{
        #main-karusel {
            margin-top: 30px;
        }
    }
}

#block-58{
    width: 100%;
    margin: 0 auto;
    background-attachment: fixed;
    background-image: url("../images/bg-58.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    color: white;
    height: 500px;
    #seminary{
        width: 1140px;
        margin: 0 auto;
        #block-block-58{
            width: 70%;
            float: left;
            #bg-58_1{
                .title{
                    clear: both;
                    float: left;
                    font-family: Open Sans;
                    height: 400px;
                    margin: 0;
                    width: 55%;
                    img{
                        float: left;
                        margin-right: 20px;
                    }
                    p.rus{
                        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                        border-top: 1px solid rgba(255, 255, 255, 0.3);
                        font-size: 18px;
                        font-weight: 700;
                        letter-spacing: 1px;
                        line-height: 2.1;
                        margin: 20px 0 0;
                        text-transform: uppercase;
                    }
                    p.eng{
                        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                        color: #cd2929;
                        font-size: 16px;
                        letter-spacing: 0.5px;
                        line-height: 1.7;
                        margin: 0 0 50px;
                    }
                    .date{
                        font-size: 50px;
                        font-weight: 700;
                    }
                    .button{
                        a{
                            background: none;
                            border: 2px solid #fff;
                            color: #fff;
                            font-size: 20px;
                            font-weight: 100;
                            letter-spacing: 2px;
                            line-height: 4;
                            padding: 12px 30px;
                            text-decoration: none;
                            text-transform: uppercase;
                            &:hover{
                                color: #fff;
                                border: 2px solid #cd2929;
                                background: #cd2929;
                            }
                        }
                        #zayavka{
                            font-size: 16px;
                            letter-spacing: 1px;
                            margin-top: 150px;
                            position: absolute;
                            text-align: center;
                            width: 1140px;
                            a{
                                border: 2px solid #ed3326;
                                color: #fff;
                                background: #ed3326;
                                font-size: 16px;
                                font-weight: bold;
                                letter-spacing: 0.7px;
                                line-height: 4;
                                padding: 15px 30px;
                                text-decoration: none;
                                text-transform: uppercase;
                                &:hover{
                                    color: #ed3326;
                                    border: 2px solid #fff;
                                    background: #fff;
                                }
                            }
                            span{
                                font-size: 24px;
                                font-weight: 800;
                            }
                        }
                    }

                }
                .content{
                    float: right;
                    line-height: 1.3;
                    margin-right: 30px;
                    margin-top: 80px;
                    text-align: right;
                    width: 40%;
                    p{
                        font-size: 14px;
                    }
                    p.head{
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: 0.5px;
                        margin: 0;
                    }
                    p.raw{
                        font-size: 14px;
                        letter-spacing: 0.3px;
                        &::after{
                            content: "\2022";
                            color: #ed3326;
                            display: block;
                            float: right;
                            margin-right: -12px;
                        }
                    }
                }
            }
        }
        #block-webform-client-block-415{
            float: right;
            width: 30%;
            h2.block-title{
                font-family: Open Sans;
                font-size: 30px;
                letter-spacing: 1px;
                line-height: 1;
                margin: 10px 0 0 -315px;
                position: absolute;
                text-align: right;
                width: 650px;
            }
            .webform-client-form{
                margin-top: 80px;
                .form-item{
                    margin: 5px 0;
                    input[type="text"]{
                        border: none;
                        letter-spacing: 0.5px;
                        line-height: 1;
                        padding: 15px 5%;
                        width: 90%;
                    }
                    textarea {
                        border: none;
                        display: block;
                        font-family: PT Sans;
                        font-size: 14px;
                        height: 120px;
                        letter-spacing: 0.5px;
                        line-height: 1.3;
                        padding: 15px 5%;
                        width: 100%;
                    }
                }
                .form-type-checkbox input,
                .form-type-checkbox label{
                    cursor: pointer;
                }
                .form-actions{
                    margin: 10px 0;
                    input{
                        color: #fff !important;
                        background: #ed3326 !important;
                        background-image: none;
                        font-size: 15px !important;
                        letter-spacing: 0.7px !important;
                        line-height: 2.5 !important;
                        margin: 0 !important;
                        padding: 0px 20px !important;
                        text-decoration: none !important;
                        border: 2px solid #ed3326 !important;
                        cursor: pointer;
                        border-radius: 0;
                        -webkit-appearance: none;
                        -webkit-border-radius: 0;
                        &:hover{
                            background: #fff !important;
                            color: #ed3326 !important;
                            border: 2px solid #fff !important;
                        }
                    }
                }
            }

        }
    }
}
.modal-forms-modal-content {
    #modal-title {
        white-space: unset;
        font-size: 25px;
        font-weight: 700;
        text-align: left;
        margin: 0px;
        float: left;
        display: inline-block;
        width: 500px;
    }
    .form-checkbox{
        width: 15px;
    }

    input {
        background: #fff none repeat scroll 0 0;
        border: 1px solid #000000;
        color: #000000;
        font-family: open sans;
        font-size: 14px;
        height: 45px;
        margin-bottom: 10px;
        margin-top: 6px;
        padding: 0 0 2px 10px;
        width: 93%;
    }
    textarea {
        background: #fff none repeat scroll 0 0;
        border: 1px solid rgb(0, 0, 0);
        color: rgb(0, 0, 0);
        font-family: open sans;
        font-size: 14px;
        margin-bottom: 10px;
        margin-top: 6px;
        padding: 0px 0px 2px 10px;
        width: 93%;
        resize: none;
        box-sizing: content-box;
    }
    .webform-component--naimenovanie {
        display:none;
    }
    .form-actions {
        input {
            background: #ed3326 none repeat scroll 0px 0px;
            border: medium none;
            color: rgb(255, 255, 255);
            font-family: "Exo 2";
            font-size: 16px;
            padding: 9px 40px;
            text-transform: uppercase;
            width: 200px;
            &:hover {
                background-color: #000;
            }
        }
    }
}
#block-block-55{
    width: 100%;
    margin: 0 auto;
    background-attachment: fixed;
    background-image: url("../images/bg-55.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    color: white;
    h2{
        font-family: Open Sans;
        font-size: 34px;
        font-weight: 800;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
        width: 1140px;
    }
    #just55_bg {
        font-family: Open Sans;
        font-size: 22px;
        letter-spacing: 0.5px;
        margin: 0 auto;
        text-align: center;
        width: 1140px;
        p{
            margin: 10px 0 30px;
        }
        a{
            background-color: #cd2929;
            color: #fff;
            font-family: Open Sans;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 1px;
            line-height: 1;
            margin: 0;
            padding: 12px 40px;
            text-decoration: none;
            &:hover{
                background-color: #fff;
                color: #cd2929; 
            }
        }
    }
}
div#block-views-programmy-block {
    background: #f0f0f0;
    padding: 100px 0;
    .view-id-programmy{
        width:1140px;
        margin:0 auto;
    }
    .view-header{
        height: 170px;
        .title{
            float: right;
            font-family: Open Sans;
            width: 45%;
            img{
                float: right;
                margin-left: 30px;
            }
            p.rus {
                border-bottom: 1px solid #ddd;
                border-top: 1px solid #ddd;
                font-size: 26px;
                font-weight: 700;
                line-height: 2;
                margin: 12px 0 0;
                text-align: right;
                text-transform: uppercase;
            }
            p.eng {
                border-bottom: 1px solid #ddd;
                color: #cd2929;
                font-size: 16px;
                letter-spacing: 0.5px;
                line-height: 1.7;
                margin: 0;
                text-align: right;
            }
        }
    }
    .view-content{
        .views-row-1{
            background: #ed6a47;
        }
        .views-row-2{
            background: #faae53;
        }
        .views-row-3{
            background: #48c4b7;
        }
        .views-row-4{
            background: #ced665;
        }
    }
    .views-row {
        border-right: 20px solid #f0f0f0;
        display: table-cell;
        height: 500px;
        width: 270px;
        &:nth-child(4){
        }
        .views-field-field-izobrazhenie-programma{
            width: 100%;
            img{
                display: block;
                height: auto;
                width: 100%;
            }
        }
        .views-field-title{
            padding: 30px 20px 15px;
            a{
                color: #fff;
                display: block;
                font-family: Open Sans;
                font-size: 18px;
                font-weight: 400;
                letter-spacing: 0.7px;
                line-height: 1.3;
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .views-field-body{
            color: #fff;
            font-size: 14px;
            height: 190px;

            line-height: 1.5;
            padding: 0 20px;
            p{
                margin: 0;
            }
        }
        .views-field-nid{
            margin: 265px 0 0;
            padding: 0 20px;
            position: absolute;
            .field-content{
                a{
                    color: #2d2d2d;
                    font-family: PT Sans;
                    background: #fff;
                    font-size: 14px;
                    letter-spacing: 0.7px;
                    line-height: 1;
                    margin: 0;
                    padding: 10px 30px;
                    text-decoration: none;
                    &:hover{
                        background-color: #2d2d2d;
                        color: #fff;
                    }
                }
            }
        }
    }
    .view-footer {
        display: none;
    }
}
#block-block-56{
    background: #f7f7f7;
    padding-bottom: 60px;
    #just {
        padding: 50px 0px;
        width: 1140px;
        margin: 0 auto;
        height: 350px;
        p.active{
            color: #fff;
            background-color: #cd2929;
        }
        .button-1.content{
            display:none;
        }          
        .button-1.content.active{
            display:block;
        }
        .button-2.content{
            display:none;
        }          
        .button-2.content.active{
            display:block;
        }
        .button-3.content{
            display:none;
        }          
        .button-3.content.active{
            display:block;
        }
        .buttons{
            p{
                background-repeat: no-repeat;
                background-size: 30px auto;
                border: 1px solid #cd2929;
                color: #cd2929;
                cursor: pointer;
                float: left;
                font-size: 18px;
                letter-spacing: 0.5px;
                margin: 0 30px 50px 0;
                padding: 14px 0;
                text-align: center;
                text-decoration: none;
                width: 358px;
                &:hover{
                    color: #fff;
                    background-color:#cd2929;
                }
            }
            #numb_1{
                background-image: url(/sites/all/themes/clipsitetheme/images/man_rr.png);
                background-position: 18%;
                &:hover{
                    background-image: url(/sites/all/themes/clipsitetheme/images/man.png);
                }
            }
            #numb_1.active{
                background-image: url(/sites/all/themes/clipsitetheme/images/man.png);
                background-position: 18%;
                color: #fff;
            }
            #numb_2{
                background-image: url(/sites/all/themes/clipsitetheme/images/map_r.png);
                background-position: 22%;
                &:hover{
                    background-image: url(/sites/all/themes/clipsitetheme/images/map.png);
                }
            }
            #numb_2.active{
                background-image: url(/sites/all/themes/clipsitetheme/images/map.png);
                background-position: 22%;
                color: #fff;
            }
            #numb_3{
                background-image: url(/sites/all/themes/clipsitetheme/images/ship_r.png);
                background-position: 4%;
                margin-right: 0;
                &:hover{
                    background-image: url(/sites/all/themes/clipsitetheme/images/ship.png);
                    background-position: 4%;
                }
            }
            #numb_3.active{
                background-image: url(/sites/all/themes/clipsitetheme/images/ship.png);
                background-position: 4%;
                color: #fff;
            }
        }
        .content {
            h4{
                font-family: Open Sans;
                font-size: 20px;
                font-weight: 700;
                letter-spacing: 0.5px;
                line-height: 1;
                margin: 0 0 20px;
            }
            .body{
                color: #444;
                float: left;
                font-family: Open Sans;
                font-size: 14px;
                height: 200px;

                line-height: 2;
                width: 55%;
                a{
                    border: 1px solid #cd2929;
                    color: #cd2929;
                    font-family: PT Sans;
                    font-size: 14px;
                    font-weight: normal;
                    letter-spacing: 1px;
                    line-height: 1;
                    margin: 30px 0 0;
                    padding: 10px 35px;
                    text-decoration: none;
                    display: block;
                    width: 90px;
                    &:hover{
                        background-color: #cd2929;
                        color: #fff;
                    }
                }
            }
            img{
                float: right;
                margin: -40px 0 0;
                width: 40%;
            }
        }

    }

}
div#block-views-ot-pervogo-lica-block {
    background-color: #eee;
    padding: 70px 0;
    .view-id-ot_pervogo_lica{
        margin: 0 auto;
        width: 1140px;
    }
    .view-header{
        height: 100px;
        margin: 0 0 50px;
        .title {
            padding: 5px 0 0;
            width: 45%;
            p.rus{
                border-bottom: 1px solid #ccc;
                border-top: 1px solid #ccc;
                font-family: Open Sans;
                font-size: 20px;
                font-weight: 700;
                line-height: 2;
                margin: 15px 0 0;
                padding: 0 0 0 160px;
                text-transform: uppercase;
            }
            p.eng{
                border-bottom: 1px solid #ccc;
                color: #cd2929;
                font-family: Open Sans;
                font-size: 16px;
                letter-spacing: 0.5px;
                line-height: 1.8;
                margin: 0;
                padding: 0 0 0 160px;
            }
            img{
                position: absolute;
            }
        }
    }
    .slick__slider > .slick-list{
        margin: 0 70px;
        padding: 0 !important;
    }
    .slick__slide {
        margin: 0px 20px;
        width: 460px !important;
    }
    .slide__content {
        margin: 0;
        display: block;
        background: none;
        border: none;
        .views-field-field-izobrazhenie-opl {
            position: absolute;
            margin: 10px 0 0 10px;
            width: 150px;
            img{
                display: block;
            }
        }
        .views-field-field-familiya-i-imya{
            color: #cd2929;
            font-family: Open Sans;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.5px;
            line-height: 1.3;
            margin: 10px 0 5px;
            padding-left: 175px;
            padding-right: 10px;

        }
        .views-field-title{
            font-family: Open Sans;
            font-size: 20px;
            letter-spacing: 0.5px;
            line-height: 1.3;
            padding-left: 175px;
            padding-right: 10px;
            a{
                color: #000;
                text-decoration: none;
                &:hover{
                    color: #cd2929; 
                }
            }
        }
        .views-field-body{
            color: #222222;
            font-family: Open Sans;
            font-size: 14px;
            margin: 10px 0;
            padding-left: 175px;
            padding-right: 10px;
            p{
                margin: 0;
            }
        }
        .views-field-nid{
            padding-left: 175px;
            margin: 0 0 20px;
            padding-right: 10px;
            a{
                color: #cd2929;
                font-family: Open Sans;
                letter-spacing: 0.5px;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
    .slick__arrow{
        .slick-arrow{
            background-color: #444;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 40% auto;
            border-radius: 0;
            height: 80px;
            text-indent: -9999px;
            width: 40px;
            &:hover{
                opacity: 0.8;
            }
            &.slick-prev{
                background-image: url("../images/elements/arrow-left.png");
            }
            &.slick-next{
                background-image: url("../images/elements/arrow-right.png");
            }
        }
    }
}

#block-block-57{
    width: 100%;
    margin: 0 auto;
    background-attachment: fixed;
    background-image: url("../images/bg-57.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    color: white;
    height: 400px;

    #just57_bg {
        width: 1140px;
        margin: 0 auto;
        div#just57{
            width: 50%;
            text-align: right;
            float: right;
            p{
                font-family: Open Sans;
                font-size: 16px;
                font-weight: 100;

                line-height: 1.7;
                margin: 30px 0 40px;
            }
            h2{
                background-color: #ed3326;
                display: block;
                font-family: Open Sans;
                font-size: 32px;
                font-weight: 800;
                line-height: 1.5;
                margin: 0;
                padding: 5px 10px;
                text-align: center;
                text-transform: uppercase;
            }
            a{
                background-color: #fff;
                color: #2d2d2d;
                font-family: Open Sans;
                font-size: 16px;
                letter-spacing: 0.7px;
                line-height: 1;
                margin: 0;
                padding: 12px 30px;
                text-decoration: none;
                &:hover{
                    background-color: #cd2929;
                    color: #fff; 
                }
            }
        }
    }
}
div#news-publikacii {
    width: 1140px;
    margin: 0 auto;
    #block-views-novosti-block{
        padding: 50px 0;
        width: 50%;
        display: table-cell;
        h2{
            background-image: url("../images/elements/split.png");
            background-position: 0 bottom;
            background-repeat: no-repeat;
            font-family: Open Sans;
            font-size: 32px;
            font-weight: 700;
            letter-spacing: 2px;
            line-height: 1.5;
            margin: 0 0 60px;
            padding: 0 0 10px;
            text-align: left;
            width: 27%;
        }
        .view{
            margin: 0 auto;
        }
        .views-row{
            display: inline-block;
            min-height: 200px;
            margin: 0 auto;
            padding: 0 80px 0 115px;
            text-align: left;
            vertical-align: top;
            .views-field.views-field-created-1 {
                color: #ef6962;
                font-family: Open Sans;
                font-size: 90px;
                font-weight: 700;
                line-height: 1;
                margin: -10px 0 0 -120px;
                position: absolute;
            }
            .views-field.views-field-created-2 {
                font-family: Open Sans;
                font-size: 18px;
                letter-spacing: 0.5px;
                line-height: 1;
                margin: 80px 0 0 -120px;
                position: absolute;
                text-align: center;
                width: 100px;
            }
        }
        .views-field-field-image-novost{
            margin: 0 0 10px;
            overflow: hidden;
            &:hover img {
                transform: scale(1.1);
            }
            img{
                display: block;
                height: auto;
                width: 100%;
                transition: 0.2s;
            }
        }
        .views-field-title{
            margin: 0 0 10px;
            text-align: left;
            a{
                color: #000;
                display: block;
                font-family: Open Sans;
                font-size: 18px;
                font-weight: 700;
                letter-spacing: 0.2px;
                line-height: 1.3;
                text-decoration: none;
                &:hover{
                    color: #cd2929;
                }
            }
        }
        .views-field-created{
            color: #999;
            font-family: PT Sans;
            font-size: 14px;
            letter-spacing: 0.5px;
            line-height: 1;
            margin: 0 0 10px;
            text-align: left;
        }
        .views-field-body{
            color: #5d5959;
            font-family: Open Sans;
            font-size: 14px;

            margin: 0;
            p{
                margin: 0;
                color: #5d5959;
                font-size:14px;
            }
        }
        .views-field-nid{
            text-align: left;
            a{
                color: #f0645a;
                text-decoration: none;
                span{
                    margin: 0 0 0 10px;
                }
                &:hover{
                    color: #2d2d2d;
                }
            }
        }
        .more-link{
            margin: 0 auto;
            text-align: right;
            padding-right: 80px;
            a{
                color: #393939;
                font-family: Open Sans;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.7px;
                line-height: 1;
                margin: 0;
                &:hover{
                    color: #f0645a;
                }
            }
        }
    }
    #block-views-publikacii-block {
        padding: 50px 0;
        display: table-cell;
        width: 50%;   
        h2.block-title{
            background-image: url("../images/elements/split.png");
            background-position: 0 bottom;
            background-repeat: no-repeat;
            font-family: Open Sans;
            font-size: 32px;
            font-weight: 700;
            letter-spacing: 2px;
            line-height: 1.5;
            margin: 0 0 60px;
            padding: 0 0 10px;
            text-align: left;
            width: 27%;
        }
        .views-row{
            display: inline-block;
            margin: 0 auto;
            min-height: 200px;
            padding: 0 0 0 200px;
            text-align: left;
            vertical-align: top;
            .views-field-field-izobrazhenie-publikacii{
                margin: 0 0 0 -200px;
                position: absolute;
            }
            .views-field-title {
                margin: 0 0 10px;
                a{
                    color: #000;
                    display: block;
                    font-family: Open Sans;
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: 0.2px;
                    line-height: 1.3;
                    text-decoration: none;
                    &:hover {
                        color: #cd2929;
                    }
                }
            }
            .views-field-body {
                font-family: Open Sans;
                font-size: 14px;
                letter-spacing: 1px;
                line-height: 1.5;
                text-align: left;
                p{
                    margin: 0;
                    color: #5d5959;
                    font-size: 14px;
                }
            }
            .views-field-nid {
                a{
                    color: #f0645a;
                    text-decoration: none;
                    &:hover {
                        color: #2d2d2d;
                    }
                }
            }

        }
        .more-link{
            margin: 0 auto;
            text-align: right;
            a{
                color: #393939;
                font-family: Open Sans;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 0.7px;
                line-height: 1;
                margin: 0;
                &:hover{
                    color: #f0645a;
                }
            }
        }
    }
}
#block-views-own-kategories-block{
    padding: 50px 0;
    h2{
        font-family: Open Sans;
        font-size: 40px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        text-transform: uppercase;
        width: 1140px;
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .view-content{
        text-align: center;
    }
    .views-row{
        border: 1px solid #ddd;
        display: inline-block;
        margin: 0 5px 10px 0;
        width: 272px;
        &:hover{
            .views-field-field-image-kategorii img{
                transform: scale(1.1);
            }
            .views-field-name {
                background-color: rgba(0, 0, 0, 0.7);
                background-size: 50px auto;
                a{
                    padding: 20px 30px 0 20px;
                    &::after{
                        width: 100%;
                    }
                }
            }
        }
    }
    .views-field-field-image-kategorii{
        overflow: hidden;
        position: relative;
        a{
            display: block;
        }
        img{
            display: block;
            height: auto;
            width: 100%;
            transition: all 0.5s ease 0s;
        }
    }
    .views-field-name{
        background-color: rgba(0, 0, 0, 0.3);
        background-image: url("../images/elements/loop.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 0;
        display: block;
        height: 204px;
        position: absolute;
        transition: all 0.4s ease 0s;
        width: 272px;
        z-index: 1;
        a{
            color: #fff;
            display: block;
            font-family: PT Sans;
            font-size: 24px;
            font-weight: 900;
            height: 184px;
            letter-spacing: 1px;
            line-height: 1.3;
            padding: 20px 10px 0 40px;
            text-align: left;
            text-decoration: none;
            transition: all 0.4s ease 0s;
            width: 222px;
            &::after{
                border-bottom: 4px solid #afb42b;
                content: "";
                display: block;
                transition: all 0.4s ease 0s;
                width: 0;
            }
        }
    }
    .view-footer{
        margin: 40px 0 20px;
        text-align: center;
        p{
            margin: 0;
        }

        a{
            border: 2px solid #afb42b;
            color: #afb42b;
            font-family: PT Sans;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.7px;
            line-height: 1;
            margin: 0;
            padding: 8px 30px;
            text-decoration: none;
            &:hover{
                background-color: #afb42b;
                color: #fff;
            }
        }
    }
}
#webform-block-1317{
    background: #ed3326;
    #block-webform-client-block-1317{
        width: 1140px;
        margin: 0 auto;
        h2.block-title{
            color: #fff;
            display: inline-block;
            float: left;
            font-family: Open Sans;
            font-size: 22px;
            padding: 10px 0 0;
            width: 600px;
        }
        #webform-client-form-1317 {
            display: inline-block;
            width: 540px;
            .webform-component-email{
                display: table-cell;
                #edit-submitted-vash-e-mail{
                    background: none;
                    border: 1px solid #fff;
                    color: #fff;
                    font-size: 14px;
                    letter-spacing: 0.7px;
                    line-height: 1;
                    margin: 40px 0;
                    padding: 15px;
                    width: 320px;
                }
            }
            .webform-component--razmetka{
                color: #fff;
                font-family: Open Sans;
                font-size: 14px;
                letter-spacing: 0.5px;
                margin: -65px 0 0 -600px;
                position: absolute;
                width: 550px;
            }
            .form-item-smplntspm1 {
                margin-top: -30px;
            }
            .form-actions{
                display: table-cell;
                margin: -96px 0 0 384px;
                position: absolute;
                input{
                    background-color: #fff;
                    border: medium none;
                    color: #2d2d2d;
                    cursor: pointer;
                    font-family: PT Sans;
                    font-size: 16px;
                    letter-spacing: 0.7px;
                    line-height: 1;
                    margin: 0 !important;
                    padding: 14px 30px;
                    text-decoration: none;
                    &:hover {
                        background-color: #2d2d2d!important;
                        color: #fff!important;
                    }
                }
            }
        }
    }
}
#block-views-blog-block{
    background-color: #efefef;
    padding: 50px 0;
    h2{
        font-family: Open Sans;
        font-size: 44px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        width: 1140px;
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .view-content{
        text-align: center;
    }
    .views-row{
        display: inline-block;
        margin: 0 10px;
        vertical-align: top;
        width: 260px;
    }
    .views-field-field-image-article{
        margin: 0 0 10px;
        overflow: hidden;
        &:hover img {
            transform: scale(1.1);
        }
        img{
            display: block;
            height: auto;
            width: 100%;
            transition: 0.2s;
        }
    }
    .views-field-title{
        margin: 0 0 10px;
        text-align: left;
        a{
            color: #000;
            display: block;
            font-family: PT Sans;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.2px;
            line-height: 1.3;
            text-decoration: none;
            &:hover{
                color: #afb42b;
            }
        }
    }
    .views-field-created{
        color: #999;
        font-family: PT Sans;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1;
        margin: 0 0 10px;
        text-align: left;
    }
    .views-field-body{
        font-size: 15px;
        letter-spacing: 0.5px;
        line-height: 1.5;
        margin: 0 0 10px;
        text-align: left;
        p{
            margin: 0;
        }
    }
    .views-field-field-rubrika{
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1.3;
        margin: 0 0 10px;
        text-align: left;
        .views-label{
            color: #999;
            float: left;
            margin: 0 5px 0 0;
        }
        a{
            color: #afb42b;
            &:hover{
                text-decoration: none;
            }
        }
    }
    .views-field-nid{
        margin: 20px 0 10px;
        text-align: left;
        a{
            background-color: #afb42b;
            color: #fff;
            line-height: 1;
            padding: 5px 20px;
            text-decoration: none;
            transition: 0.1s;
            span{
                margin: 0 0 0 10px;
            }
            &:hover{
                box-shadow: 0px 3px 3px -2px #444;
            }
        }
    }
    .more-link{
        margin: 40px 0 20px;
        text-align: center;

        a{
            border: 2px solid #afb42b;
            color: #afb42b;
            font-family: PT Sans;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.7px;
            line-height: 1;
            margin: 0;
            padding: 8px 30px;
            text-decoration: none;
            &:hover{
                background-color: #afb42b;
                color: #fff;
            }
        }
    }
}
#block-views-akcii-block{
    background-color: #efefef;
    padding: 50px 0;
    h2{
        font-family: Open Sans;
        font-size: 44px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        width: 1140px;
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .view-content{
        text-align: center;
    }
    .views-row{
        display: inline-block;
        margin: 0 10px;
        vertical-align: top;
        width: 260px;
    }
    .views-field-field-image-akciya{
        margin: 0 0 10px;
        overflow: hidden;
        &:hover img {
            transform: scale(1.1);
        }
        img{
            display: block;
            height: auto;
            width: 100%;
            transition: 0.2s;
        }
    }
    .views-field-title{
        margin: 0 0 10px;
        text-align: left;
        a{
            color: #000;
            display: block;
            font-family: PT Sans;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.2px;
            line-height: 1.3;
            text-decoration: none;
            &:hover{
                color: #afb42b;
            }
        }
    }
    .views-field-body{
        font-size: 15px;
        letter-spacing: 0.5px;
        line-height: 1.5;
        margin: 0 0 10px;
        text-align: left;
        p{
            margin: 0;
        }
    }
    .views-field-field-until {
        color: #999;
        font-family: PT Sans;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 16px;
        margin: 0 0 10px;
        text-align: left;
        .views-label {
            float: left;
            margin: 0 5px 0 0;
        }
        .field-content {
            font-size: 16px;
        }
    }
    .views-field-nid{
        margin: 20px 0 10px;
        text-align: left;
        a{
            background-color: #afb42b;
            color: #fff;
            line-height: 1;
            padding: 5px 20px;
            text-decoration: none;
            transition: 0.1s;
            span{
                margin: 0 0 0 10px;
            }
            &:hover{
                box-shadow: 0px 3px 3px -2px #444;
            }
        }
    }
    .more-link{
        margin: 40px 0 20px;
        text-align: center;

        a{
            border: 2px solid #afb42b;
            color: #afb42b;
            font-family: PT Sans;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.7px;
            line-height: 1;
            margin: 0;
            padding: 8px 30px;
            text-decoration: none;
            &:hover{
                background-color: #afb42b;
                color: #fff;
            }
        }
    }
}

#block-views-nashi-klienty-block{
    padding: 50px 0;
    h2{
        font-family: Open Sans;
        font-size: 44px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        width: 1140px;
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .view-content{
        text-align: center;
    }
    .views-row{
        display: inline-block;
        margin: 0 10px 20px;
        width: 180px;
    }
    .views-field-field-image-logo-klient{
        display: table-cell;
        height: 140px;
        vertical-align: middle;
        width: 180px;
        .field-content {
            display: block;
            margin: 0 auto;
            width: 120px;
        }
        img{
            display: block;
            height: auto;
            margin: 0 auto;
            max-height: 140px;
            max-width: 120px;
        }
    }
    .views-field-title{
        color: #999;
        font-family: Open Sans;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.3px;
        margin: 0;
    }
}
#block-views-nashi-partnery-block{
    padding: 50px 0;
    h2{
        font-family: Open Sans;
        font-size: 44px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        width: 1140px;
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .view-content{
        text-align: center;
    }
    .views-row{
        display: inline-block;
        margin: 0 10px 20px;
        width: 180px;
    }
    .views-field-field-image-logo-partner{
        display: table-cell;
        height: 140px;
        vertical-align: middle;
        width: 180px;
        .field-content {
            display: block;
            margin: 0 auto;
            width: 120px;
        }
        img{
            display: block;
            height: auto;
            margin: 0 auto;
            max-height: 140px;
            max-width: 120px;
        }
    }
    .views-field-title{
        color: #999;
        font-family: Open Sans;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0.3px;
        margin: 0;
    }
}
#block-views-otzyvy-personal-block{
    padding: 50px 0;
    h2{
        font-family: Open Sans;
        font-size: 44px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        width: 1140px;
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .view-content{
        text-align: center;
    }
    .views-row{
        background-color: #efefef;
        display: inline-block;
        margin: 0 5px;
        padding: 0 0 40px;
        vertical-align: top;
        transition: 0.2s;
        width: 270px;
        &:hover{
            box-shadow: 0 2px 12px 0px rgba(0,0,0,0.3);
            .views-field-field-image-otzyvfoto{
                .field-content::before{
                    transform: scale(1.1);
                }
            }
        }
    }
    .views-field-field-image-otzyvfoto{
        padding: 30px;
        img{
            border-radius: 150px;
            display: block;
            height: auto;
            position: relative;
            width: 100%;
            z-index: 1;
        }
        .field-content::before {
            border: 5px solid #afb42b;
            border-radius: 150px;
            content: "";
            display: block;
            height: 200px;
            position: absolute;
            transition: all 0.2s ease 0s;
            width: 200px;
            z-index: 0;
        }
    }
    .views-field-title{
        font-family: Open Sans;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.5px;
        line-height: 1.3;
        padding: 0 10px;
    }
    .views-field-field-image-doljnost{
        color: #999;
        font-size: 14px;
        line-height: 1.3;
        margin: 0 0 20px;
        padding: 0 10px;
    }
    .views-field-body{
        background-image: url("../images/elements/citata.png");
        background-position: 10px 0;
        background-repeat: no-repeat;
        background-size: 30px auto;
        color: #444;
        font-size: 14px;
        font-style: italic;
        letter-spacing: 0.5px;
        line-height: 1.7;
        padding: 0 10px 0 50px;
        text-align: left;
    }
    .more-link{
        margin: 40px 0 20px;
        text-align: center;

        a{
            border: 2px solid #afb42b;
            color: #afb42b;
            font-family: PT Sans;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.7px;
            line-height: 1;
            margin: 0;
            padding: 8px 30px;
            text-decoration: none;
            &:hover{
                background-color: #afb42b;
                color: #fff;
            }
        }
    }
}
#block-views-katalog-block{
    background-color: #efefef;
    padding: 50px 0;
    h2{
        font-family: Open Sans;
        font-size: 44px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        width: 1140px;
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .view-content{
        text-align: center;
    }
    .views-row {
        background-color: #fff;
        box-shadow: 0 1px 3px -1px #444;
        display: inline-block;
        margin: 0 10px 40px;
        vertical-align: top;
        transition: 0.2s;
        width: 260px;
        &:hover{
            box-shadow: 0 3px 7px -1px #444;
            .views-field-field-image-tovar img{
                transform: scale(1.1);
            }
        }
        .views-field{
            border-bottom: 1px solid #ddd;
            &:nth-child(even){
                background-color: #e7e7e7;
            }
        }
    }
    .views-field-field-image-tovar{
        overflow: hidden;
        img{
            display: block;
            height: auto;
            transition: 0.2s;
            width: 100%;
        }
    }
    .views-field-title{
        text-align: left;
        a{
            color: #000;
            display: block;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.3px;
            line-height: 1.3;
            padding: 5px 15px;
            text-decoration: none;
            &:hover{
                color: #afb42b;
            }
        }
    }
    .views-field-field-atribut-proizvoditel{
        font-size: 13px;
        padding: 5px 15px;
        text-align: left;
        .views-label{
            color: #777;
            float: left;
            margin: 0 5px 0 0;
        }
    }
    .views-field-field-atribut-strana{
        font-size: 13px;
        padding: 5px 15px;
        text-align: left;
        .views-label{
            color: #777;
            float: left;
            margin: 0 5px 0 0;
        }
    }
    .views-field-field-atribut-cvet{
        font-size: 13px;
        padding: 5px 15px;
        text-align: left;
        .views-label{
            color: #777;
            float: left;
            margin: 0 5px 0 0;
        }
    }
    .views-field-body{
        color: #777;
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 1.5;
        padding: 5px 15px;
        text-align: left;
    }
    .views-field-field-cena{
        font-family: Open Sans;
        font-size: 30px;
        font-weight: 100;
        letter-spacing: 0.7px;
        line-height: 1;
        padding: 10px 15px;
        text-transform: uppercase;
    }
    .views-field-nid{
        border: none !important;
        a{
            background-color: #afb42b;
            color: #fff;
            display: block;
            letter-spacing: 0.7px;
            padding: 5px 15px;
            text-decoration: none;
            text-transform: uppercase;
            &:hover{
                opacity: 0.8;
            }
        }
    }
    .more-link {
        margin: 40px 0 20px;
        text-align: center;
        a{
            border: 2px solid #afb42b;
            color: #afb42b;
            font-family: PT Sans;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.7px;
            line-height: 1;
            padding: 8px 30px;
            text-decoration: none;
            &:hover{
                background-color: #afb42b;
                color: #fff;
            }
        }
    }
}
#block-views-katalog-block-1{
    padding: 50px 0;
    h2{
        font-family: Open Sans;
        font-size: 44px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        width: 1140px;
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .view-content{
        text-align: center;
    }
    .views-row {
        background-color: #fff;
        box-shadow: none;
        display: inline-block;
        margin: 0 10px 40px;
        vertical-align: top;
        transition: 0.2s;
        width: 260px;
        &:hover{
            box-shadow: none;
            img{
                transform: none;
            }
        }
        .views-field{
            background: none;
            border: none;
        }
    }
    .views-field-field-image-tovar{
        box-shadow: 0 1px 3px -1px #444;
        overflow: hidden;
        img{
            display: block;
            height: auto;
            transition: 0.2s;
            width: 100%;
        }
    }
    .views-field-title{
        a{
            color: #000;
            display: block;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.3px;
            line-height: 1.3;
            padding: 15px 0 0;
            text-align: center;
            text-decoration: none;
            &:hover{
                color: #afb42b;
            }
        }
    }
    .views-field-body{
        color: #777;
        font-size: 14px;
        letter-spacing: 0.3px;
        line-height: 1.5;
        padding: 5px 15px;
        text-align: left;
    }
    .views-field-field-cena{
        font-family: Open Sans;
        font-size: 30px;
        font-weight: 100;
        letter-spacing: 0.7px;
        line-height: 1;
        padding: 10px 15px 15px;
        text-transform: uppercase;
    }
    .views-field-nid{
        border: none !important;
        a{
            background-color: #afb42b;
            color: #fff;
            display: inherit;
            letter-spacing: 0.7px;
            padding: 8px 25px;
            text-decoration: none;
            text-transform: uppercase;
            &:hover{
                opacity: 0.8;
            }
        }
    }
    .more-link {
        margin: 40px 0 20px;
        text-align: center;
        a{
            border: 2px solid #afb42b;
            color: #afb42b;
            font-family: PT Sans;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.7px;
            line-height: 1;
            padding: 8px 30px;
            text-decoration: none;
            &:hover{
                background-color: #afb42b;
                color: #fff;
            }
        }
    }
}
#block-block-50{
    background-attachment: fixed;
    background-image: url("../images/bg1.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    h2{
        color: #fff;
        font-family: Open Sans;
        font-size: 50px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        text-transform: uppercase;
        width: 1140px;
        strong{
            display: block;
            font-weight: 800;
            line-height: 1.3;
            width: 100%;
        }
    }
    p{
        color: #fff;
        font-family: Open Sans;
        font-size: 18px;
        font-weight: 100;
        letter-spacing: 1px;
        margin: 0 auto;
        text-align: center;
        width: 1000px;
    }
}
#block-block-51{
    background-attachment: fixed;
    background-image: url("../images/bg2.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    h2{
        color: #fff;
        font-family: Open Sans;
        font-size: 50px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        text-transform: uppercase;
        width: 1140px;
        strong{
            display: block;
            font-weight: 800;
            line-height: 1.3;
            width: 100%;
        }
    }
    p{
        color: #fff;
        font-family: Open Sans;
        font-size: 18px;
        font-weight: 100;
        letter-spacing: 1px;
        margin: 0 auto;
        text-align: center;
        width: 1000px;
    }
}
#block-webform-client-block-1283{
    background-attachment: fixed;
    background-image: url("../images/bg1.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px 0;
    h2{
        color: #fff;
        font-family: Open Sans;
        font-size: 50px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        text-transform: uppercase;
        width: 1140px;
    }
    .node-webform{
        background: none;
        box-shadow: none;
        margin: 0 auto;
        padding: 0;
        text-align: center;
        width: 1140px;
        header{
            display: none;
        }
        p{
            color: #fff;
            font-family: Open Sans;
            font-size: 20px;
            font-weight: 100;
            letter-spacing: 1.5px;
            margin: 0 auto 40px;
            text-align: center;
            width: 800px;
        }
        .form-item{
            margin: 0 0 15px;
        }
        input[type="text"],
        input[type="email"] {
            background-color: transparent;
            border: 1px solid #ccc;
            color: #fff;
            font-family: Open Sans;
            font-size: 18px;
            font-weight: 100;
            padding: 10px 15px;
            text-transform: none;
            width: 550px;
        }
        .form-actions{
            margin: 40px 0 0;
            input {
                background: none;
                border: 1px solid #fff;
                color: #fff;
                cursor: pointer;
                font-family: Open Sans;
                font-size: 20px;
                font-weight: 100;
                letter-spacing: 0.7px;
                margin: 0;
                padding: 7px 30px;
                text-decoration: none;
                &:hover{
                    background-color: #afb42b;
                    border-color: #afb42b;
                }
            }
        }

    }

}
#block-views-blagodarnosti-block-1{
    background-attachment: fixed;
    background-image: url("../images/bg2.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px 0 100px;
    h2{
        color: #fff;
        font-family: Open Sans;
        font-size: 50px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 70px;
        text-align: center;
        text-transform: uppercase;
        width: 1140px;
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .slide__content{
        margin: 0;
    }
    .slick__slider{
        margin: 0 auto;
        width: 1000px;
        img:hover{
            opacity: 0.8;
        }
    }
    .slick-prev::before{
        background-image: url("../images/elements/arrow-left.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        color: white;
        display: block;
        height: 50px;
        opacity: 1;
        text-indent: -9999px;
        &:hover{
            opacity: 0.8;
        }
    }
    .slick-next::before{
        background-image: url("../images/elements/arrow-right.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% auto;
        color: white;
        display: block;
        height: 50px;
        opacity: 1;
        text-indent: -9999px;
        &:hover{
            opacity: 0.8;
        }
    }
    .more-link{
        margin: 70px 0 0;
        text-align: center;
        a{
            border: 1px solid #fff;
            color: #fff;
            font-family: Open Sans;
            font-size: 20px;
            font-weight: 100;
            letter-spacing: 0.7px;
            margin: 0;
            padding: 7px 30px;
            text-decoration: none;
            &:hover{
                background-color: #afb42b;
                border-color: #afb42b;
            }
        }
    }
}
/* End of main page */

/* not-front */
.not-front{
    #page{
        background-color: #efefef;
    }
    #header {
        background-image: url("../images/bg-52.jpg");
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
    }
    #columns{
        margin: 0 auto;
        padding: 100px 0 0;
        width: 1140px;
    }
    #content{
        background-color: transparent !important;
    }
    .region-sidebar-first{
        width: 280px;
    }
    #content-column{
        opacity: 1;
    }    
    h1{
        font-family: "Exo 2";
        font-size: 40px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 1.2;
        margin: 30px 0 20px;
    }

    #block-block-40 h3 a{
        color: #fff;
    }
    #superfish-1 li a{
        color: #fff;
    }
    .node-content{
        margin: 0 0 100px;
        figure{
            float: right;
            margin: 0 0 10px 40px;
            img{
                background-color: #fff;
                border: 1px solid #ccc;
                padding: 10px;
            }
        }
        .field-name-body{
            font-family: Open Sans;
            letter-spacing: 0.5px;
            line-height: 1.7;
            margin: 0 0 10px;
            p{
                margin: 0 0 15px;
            }
            .video-filter{
                margin: 20px 0;
            }
            ul{
                padding: 0 0 0 50px;
                li{
                    font-size: 14px;
                    list-style: outside none none;
                    padding: 0 0 5px;
                    &::before{
                        background-color: #fff;
                        border: 2px solid #cd2929;
                        border-radius: 20px;
                        content: "";
                        display: block;
                        height: 5px;
                        margin: 11px 0 0 -20px;
                        position: absolute;
                        width: 5px;
                    }
                }
            }
            ol{
                counter-reset: point;
                list-style: outside none none;
                padding: 0 0 0 50px;
                li{
                    font-size: 14px;
                    padding: 0 0 5px 20px;
                    &::before {
                        border: 1px solid;
                        border-radius: 30px;
                        color: #cd2929;
                        content: counter(point, decimal);
                        counter-increment: point 1;
                        display: inline-block;
                        font-family: PT Sans;
                        font-size: 12px;
                        line-height: 1;
                        margin-right: 20px;
                        padding: 3px 0;
                        text-align: center;
                        width: 18px;
                    }
                }
            }
            a{
                color: #cd2929;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}
/* end of not-front */

/* Catalog */
.view-katalog,
#block-views-katalog-block-2,
.view-novinki,
#block-views-novinki-block-2{
    .views-row {
        background-color: #fff;
        box-shadow: 0 1px 3px -1px #444;
        display: inline-block !important;
        margin: 0 5px 40px;
        transition: all 0.2s ease 0s;
        vertical-align: top;
        width: 200px;
        &:hover{
            box-shadow: 0 3px 7px -1px #444;
            .views-field-field-image-tovar img{
                transform: scale(1.1);
            }
        }
        .views-field{
            border-bottom: 1px solid #ddd;
            &:nth-child(even){
                background-color: #e7e7e7;
            }
        }
    }
    .views-field-field-image-tovar{
        overflow: hidden;
        img{
            display: block;
            height: auto;
            transition: 0.2s;
            width: 100%;
        }
    }
    .views-field-title{
        text-align: left;
        a{
            color: #000;
            display: block;
            font-family: Open Sans;
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 1.3;
            padding: 3px 10px;
            text-decoration: none;
            &:hover{
                color: #afb42b;
            }
        }
    }
    .views-field-field-atribut-proizvoditel{
        font-size: 12px;
        padding: 3px 10px;
        text-align: left;
        .views-label{
            color: #777;
            float: left;
            margin: 0 5px 0 0;
        }
    }
    .views-field-field-atribut-strana{
        font-size: 12px;
        padding: 4px 10px;
        text-align: left;
        .views-label{
            color: #777;
            float: left;
            margin: 0 5px 0 0;
        }
    }
    .views-field-field-atribut-cvet{
        font-size: 12px;
        padding: 4px 10px;
        text-align: left;
        .views-label{
            color: #777;
            float: left;
            margin: 0 5px 0 0;
        }
    }
    .views-field-body{
        color: #777;
        font-size: 12px;
        letter-spacing: 0.3px;
        line-height: 1.5;
        padding: 4px 10px;
        text-align: left;
    }
    .views-field-field-cena{
        font-family: Open Sans;
        font-size: 24px;
        font-weight: 100;
        letter-spacing: 0.7px;
        line-height: 1;
        padding: 7px 10px;
        text-transform: uppercase;
    }
    .views-field-nid{
        border: none !important;
        a{
            background-color: #afb42b;
            color: #fff;
            display: block;
            letter-spacing: 0.7px;
            padding: 4px 10px;
            text-align: center;
            text-decoration: none;
            text-transform: uppercase;
            &:hover{
                opacity: 0.8;
            }
        }
    }
}
/* End of catalog */

/* category */
.view-kategory-page{
    opacity: 1;
    .views-row{
        display: inline-block;
        margin: 0 10px 30px 0;
        vertical-align: top;
        width: 272px;
        &:hover{
            .views-field-field-image-kategorii img{
                transform: scale(1.1);
            }
        }
    }
    .views-field-field-image-kategorii{
        box-shadow: 0 1px 3px -1px #444;
        overflow: hidden;
        img{
            display: block;
            height: auto;
            transition: 0.4s;
            width: 100%;
        }
    }
    .views-field-name{
        border-top: 1px solid #ddd;
        margin: 12px 0 0;
        a{
            color: #000;
            display: block;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 0.7px;
            line-height: 1.3;
            padding: 7px 15px 0;
            text-decoration: none;
            transition: all 0.2s ease 0s;
            &:hover{
                color: #afb42b;
            }
        }
    }
}
/* end of category */

/* Sidebar */
#block-superfish-3{
    background-color: #efefef;
    margin: 0 0 20px;
    padding: 20px 0;
    width: 240px;
    h2{
        font-family: PT Sans;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.7px;
        line-height: 1.3;
        margin: 0;
        opacity: 1;
        padding: 20px 15px;
        text-transform: uppercase;
    }
}
#superfish-3{
    width: auto;
    li{
        margin: 0;
        width: 100%;
        &.sgHover{
            opacity: 1;
            a{
                opacity: 1;
            }
        }
        ul{
            background-color: #efefef;
            margin: 0 0 0 50px;
            width: 240px !important;
            li{
                opacity: 1;
                a{
                    opacity: 1;
                }
                ul{
                    margin: 0 0 0 1px;
                }
            }
        }
        &.first a{
            border-top: 1px solid #ddd;
        }
    }
    a{
        border-bottom: 1px solid #ddd;
        color: #232323;
        display: block;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 1;
        padding: 10px 15px;
        text-decoration: none;
        &:hover{
            background-color: #afb42b;
            color: #fff;
            span{
                color: #fff;
            }
        }
        span{
            background: none;
            color: #999;
            font-size: 16px;
            height: 16px;
            padding: 10px 0;
            text-align: right;
            text-indent: 0;
            top: 0;
            width: 30px;
        }
    }
}
#block-views-novosti-block-1{
    background-color: #efefef;
    margin: 0 0 20px;
    padding: 20px 0 0;
    width: 240px;
    h2{
        font-family: PT Sans;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.7px;
        line-height: 1.3;
        margin: 0;
        opacity: 1;
        padding: 10px 15px;
        text-transform: uppercase;
    }
    .views-row{
        border-bottom: 1px solid #ddd;
        &.views-row-last{
            border: none;
        }
        &:hover{
            background-color: #f7f7f7;
        }
        a{
            display: block;
            min-height: 70px;
            padding: 10px 15px 10px 100px;
            text-decoration: none;
            &:hover{
                .title{
                    color: #afb42b;
                }
            }
        }
    }
    .image{
        box-shadow: 0 1px 3px -1px #ccc;
        height: 70px;
        margin: 0 0 0 -85px;
        overflow: hidden;
        position: absolute;
        width: 70px;
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
    .date{
        color: #999;
        font-size: 14px;
        letter-spacing: 0.7px;
        line-height: 1;
        margin: 0 0 5px;
        text-decoration: none;
    }
    .title{
        color: #000;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1.1;
        text-decoration: none;
    }
    .more-link{
        margin: 10px 0 0;
        text-align: center;
        a{
            background-color: #afb42b;
            color: #fff;
            display: block;
            letter-spacing: 1px;
            padding: 5px 0;
            text-decoration: none;
            text-transform: uppercase;
            &:hover{
                opacity: 0.8;
            }
        }
    }
}
#block-views-akcii-block-1{
    background-color: #efefef;
    margin: 0 0 20px;
    padding: 20px 0 0;
    width: 240px;
    h2{
        font-family: PT Sans;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.7px;
        line-height: 1.3;
        margin: 0;
        opacity: 1;
        padding: 10px 15px;
        text-transform: uppercase;
    }
    .views-row{
        border-bottom: 1px solid #ddd;
        &.views-row-last{
            border: none;
        }
        &:hover{
            background-color: #f7f7f7;
        }
        a{
            display: block;
            min-height: 70px;
            padding: 10px 15px 10px 100px;
            text-decoration: none;
            &:hover{
                .title{
                    color: #afb42b;
                }
            }
        }
    }
    .image{
        box-shadow: 0 1px 3px -1px #ccc;
        height: 70px;
        margin: 0 0 0 -85px;
        overflow: hidden;
        position: absolute;
        width: 70px;
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
    .date{
        color: #999;
        font-size: 10px;
        letter-spacing: 0.3px;
        line-height: 1.3;
        margin: 5px 0 0;
        text-decoration: none;
    }
    .title{
        color: #000;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1.1;
        text-decoration: none;
    }
    .more-link{
        margin: 10px 0 0;
        text-align: center;
        a{
            background-color: #afb42b;
            color: #fff;
            display: block;
            letter-spacing: 1px;
            padding: 5px 0;
            text-decoration: none;
            text-transform: uppercase;
            &:hover{
                opacity: 0.8;
            }
        }
    }
}
#block-views-rubriki-block{
    background-color: #efefef;
    margin: 0 0 20px;
    padding: 20px 0 0;
    width: 240px;
    h2{
        font-family: PT Sans;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.7px;
        line-height: 1.3;
        margin: 0;
        opacity: 1;
        padding: 20px 15px;
        text-transform: uppercase;
    }
    .views-row{
        margin: 0;
        width: 100%;
        &.views-row-first a{
            border-top: 1px solid #ddd;
        }
    }
    .view-content a{
        border-bottom: 1px solid #ddd;
        color: #232323;
        display: block;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 1;
        padding: 10px 15px;
        text-decoration: none;
        &:hover{
            background-color: #afb42b;
            color: #fff;
        }
    }
    .view-footer{
        margin: 0;
        text-align: center;
        a{
            background-color: #afb42b;
            color: #fff;
            display: block;
            letter-spacing: 1px;
            padding: 5px 0;
            text-decoration: none;
            text-transform: uppercase;
            &:hover{
                opacity: 0.8;
            }
        }
    }
}
#block-views-blog-block-2{
    background-color: #efefef;
    margin: 0 0 20px;
    padding: 20px 0 0;
    width: 240px;
    h2{
        font-family: PT Sans;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0.7px;
        line-height: 1.3;
        margin: 0;
        opacity: 1;
        padding: 10px 15px;
        text-transform: uppercase;
    }
    .views-row{
        border-bottom: 1px solid #ddd;
        &.views-row-last{
            border: none;
        }
        &:hover{
            background-color: #f7f7f7;
        }
        a{
            display: block;
            min-height: 70px;
            padding: 10px 15px 10px 100px;
            text-decoration: none;
            &:hover{
                .title{
                    color: #afb42b;
                }
            }
        }
    }
    .image{
        box-shadow: 0 1px 3px -1px #ccc;
        height: 70px;
        margin: 0 0 0 -85px;
        overflow: hidden;
        position: absolute;
        width: 70px;
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
    .date{
        color: #999;
        font-size: 14px;
        letter-spacing: 0.7px;
        line-height: 1;
        margin: 0 0 5px;
        text-decoration: none;
    }
    .title{
        color: #000;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1.1;
        text-decoration: none;
    }
    .more-link{
        margin: 10px 0 0;
        text-align: center;
        a{
            background-color: #afb42b;
            color: #fff;
            display: block;
            letter-spacing: 1px;
            padding: 5px 0;
            text-decoration: none;
            text-transform: uppercase;
            &:hover{
                opacity: 0.8;
            }
        }
    }
}
/* End of sidebar */

/* Product cart */
#block-views-kartochka-tovara-block{
    .views-row{
        padding: 0 0 0 340px;
        .views-field{
            border-bottom: 1px solid #ccc;
            font-size: 18px;
            letter-spacing: 0.5px;
            padding: 10px 0;
        }
    }
    .views-field-field-image-tovar{
        border: none !important;
        margin: 0 0 0 -340px;
        padding: 0 !important;
        position: absolute;
        width: 300px;
        img{
            box-shadow: 0 1px 3px -1px #444;
            display: block;
            height: auto;
            width: 100%;
        }
    }
    .views-field-field-atribut-proizvoditel{
        opacity: 1;
        .views-label{
            float: left;
            font-weight: bold;
            margin: 0 10px 0 0;
        }
    }
    .views-field-field-atribut-strana{
        opacity: 1;
        .views-label{
            float: left;
            font-weight: bold;
            margin: 0 10px 0 0;
        }
    }
    .views-field-field-atribut-cvet{
        opacity: 1;
        .views-label{
            float: left;
            font-weight: bold;
            margin: 0 10px 0 0;
        }
    }
    .views-field-body{

    }
    .views-field-field-cena{
        padding: 0 !important;
        .views-label{
            float: left;
            font-weight: bold;
            margin: 40px 20px 0 0;
        }
        .field-content{
            color: #afb42b;
            font-family: Open Sans;
            font-size: 50px;
            font-weight: 100;
        }
    }
    .views-field-yashare{
        border: none !important;
        .views-label{
            float: left;
            margin: 0 10px 0 0;
        }
    }
}
/* End of product cart */

/* Order product */
#block-webform-client-block-1267{
    background-attachment: fixed;
    background-image: url("../images/bg-58.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 70px 0;
    .webform-component--naimenovanie{
        display: none;
    }
    .block-inner{
        margin: 0 auto;
        width: 1140px;
    }
    h2{
        color: #fff;
        font-family: Open Sans;
        font-size: 34px;
        font-weight: 800;
        letter-spacing: 2px;
        line-height: 1.2;
        margin: 0 auto 20px;
        padding: 20px 0;
        text-align: center;
        text-transform: uppercase;
        width: 60%;
    }
    form{
        margin: 0 auto;
        width: 500px;
    }
    .form-item{
        color: #fff;
        font-family: Open Sans;
        font-size: 14px;
        letter-spacing: 1px;
        margin: 0 0 10px;
    }
    .webform-component--tekst{
        border-left: 1px solid #ccc;
        border-top: none;
        border-right: none;
        border-bottom: none;
        color: #999;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 100;
        letter-spacing: 0.7px;
        line-height: 2;
        margin: 0 0 0 520px;
        padding: 20px 0 20px 30px;
        position: absolute;
        width: 250px;
    }
    input[type="text"],
    input[type="email"]{
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;
        font-family: Open Sans;
        font-size: 16px;
        letter-spacing: 1px;
        line-height: 1;
        padding: 15px;
        width: 450px;
    }
    .webform-component--naimenovanie{
        label{
            font-size: 12px;
            font-weight: normal;
            line-height: 1;
            padding: 5px 0 2px 15px;
        }
    }
    .form-actions{
        opacity: 1;
        input{
            background-color: #ed3326;
            border: 2px solid #ed3326;
            color: #fff;
            cursor: pointer;
            display: block;
            font-family: PT Sans;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.7px;
            line-height: 1;
            padding: 12px 35px;
            text-decoration: none;
            text-transform: uppercase;
            &:hover{
                background-color: #fff;
                border: 2px solid #fff;
                color: #ed3326;
            }
        }
    }
}
#block-views-novinki-block-2{
    h2{
        font-family: Open Sans;
        font-size: 30px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0;
        padding: 20px 0;
        text-transform: uppercase;
    }
    .more-link{
        margin: 10px 0 20px;
        text-align: center;
        a{
            border: 2px solid #afb42b;
            color: #afb42b;
            font-family: PT Sans;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 1;
            padding: 7px 30px;
            text-decoration: none;
            &:hover{
                background-color: #afb42b;
                color: #fff;
            }
        }
    }
}
/* End of order product */


/* Blog */
#block-system-main .view-blog{
    h2{
        font-family: Open Sans;
        font-size: 44px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        width: 1140px;
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .views-row{
        background-color: #efefef;
        display: inline-block !important;
        margin: 0 12px 17px 0;
        vertical-align: top;
        width: 270px;
        &:hover{
            box-shadow: 0 1px 3px -1px #444;
        }
    }
    .views-field-field-image-article{
        margin: 0 0 10px;
        overflow: hidden;
        &:hover img {
            transform: scale(1.1);
        }
        img{
            display: block;
            height: auto;
            width: 100%;
            transition: 0.2s;
        }
    }
    .views-field-title{
        margin: 0 0 10px;
        padding: 0 15px;
        text-align: left;
        a{
            color: #000;
            display: block;
            font-family: PT Sans;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.2px;
            line-height: 1.3;
            text-decoration: none;
            &:hover{
                color: #afb42b;
            }
        }
    }
    .views-field-created{
        color: #999;
        font-family: PT Sans;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1;
        margin: 0 0 10px;
        padding: 0 15px;
        text-align: left;
    }
    .views-field-body{
        font-size: 15px;
        letter-spacing: 0.5px;
        line-height: 1.5;
        margin: 0 0 10px;
        padding: 0 15px;
        text-align: left;
        p{
            margin: 0;
        }
    }
    .views-field-field-rubrika{
        font-size: 14px;
        line-height: 1.3;
        padding: 0 15px;
        .views-label{
            color: #999;
            float: left;
            margin: 0 5px 0 0;
        }
        a{
            color: #afb42b;
            letter-spacing: 0.5px;
            &:hover{
                text-decoration: none;
            }
        }
    }
    .views-field-nid{
        margin: 20px 0;
        padding: 0 15px;
        text-align: left;
        a{
            background-color: #afb42b;
            color: #fff;
            line-height: 1;
            padding: 5px 20px;
            text-decoration: none;
            transition: 0.1s;
            span{
                margin: 0 0 0 10px;
            }
            &:hover{
                box-shadow: 0px 3px 3px -2px #444;
            }
        }
    }
}
#block-views-rubriki-block-1{
    .views-field-name{
        font-family: Open Sans;
        font-size: 40px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1.2;
        margin: 30px 0 40px;
        text-transform: uppercase;
    }
}
#block-views-blog-block-1{
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .views-row{
        background-color: #efefef;
        display: inline-block !important;
        margin: 0 12px 17px 0;
        vertical-align: top;
        width: 270px;
        &:hover{
            box-shadow: 0 1px 3px -1px #444;
        }
    }
    .views-field-field-image-article{
        margin: 0 0 10px;
        overflow: hidden;
        &:hover img {
            transform: scale(1.1);
        }
        img{
            display: block;
            height: auto;
            width: 100%;
            transition: 0.2s;
        }
    }
    .views-field-title{
        margin: 0 0 10px;
        padding: 0 15px;
        text-align: left;
        a{
            color: #000;
            display: block;
            font-family: PT Sans;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.2px;
            line-height: 1.3;
            text-decoration: none;
            &:hover{
                color: #afb42b;
            }
        }
    }
    .views-field-created{
        color: #999;
        font-family: PT Sans;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1;
        margin: 0 0 10px;
        padding: 0 15px;
        text-align: left;
    }
    .views-field-body{
        font-size: 15px;
        letter-spacing: 0.5px;
        line-height: 1.5;
        margin: 0 0 10px;
        padding: 0 15px;
        text-align: left;
        p{
            margin: 0;
        }
    }
    .views-field-field-rubrika{
        font-size: 14px;
        line-height: 1.3;
        padding: 0 15px;
        .views-label{
            color: #999;
            float: left;
            margin: 0 5px 0 0;
        }
        a{
            color: #afb42b;
            letter-spacing: 0.5px;
            &:hover{
                text-decoration: none;
            }
        }
    }
    .views-field-nid{
        margin: 20px 0;
        padding: 0 15px;
        text-align: left;
        a{
            background-color: #afb42b;
            color: #fff;
            line-height: 1;
            padding: 5px 20px;
            text-decoration: none;
            transition: 0.1s;
            span{
                margin: 0 0 0 10px;
            }
            &:hover{
                box-shadow: 0px 3px 3px -2px #444;
            }
        }
    }
}
/* End of blog */

/* Gallery */
#block-system-main .view-galereya {
    .views-row{
        display: inline-block;
        margin: 0 10px 20px 0;
        vertical-align: top;
        width: 270px;
        &:hover{
            img{
                transform: scale(1.1);
                opacity: 0.8;
            }
            .views-field-title a{
                color: #afb42b;
            }
        }
    }
    .views-field-field-image-oblojka{
        background-color: #444;
        overflow: hidden;
        img{
            display: block;
            height: auto;
            transition: 0.2s;
            width: 100%;
        }
    }
    .views-field-title{
        margin: 5px 0 0;
        a{
            color: #000;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0.5px;
            line-height: 1.3;
            text-decoration: none;
        }
    }
}
/* End of gallery */

/* Pages */
.node-novost{
    .field-name-field-image-novost{
        box-shadow: 0 1px 3px -1px #444;
        margin: 0 0 20px;
        width: 400px;
        figure{
            margin: 0;
        }
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
    .field-name-body{
        font-size: 18px;
        letter-spacing: 0.3px;
    }
}
.node-akciya{
    .field-name-field-image-akciya{
        box-shadow: 0 1px 3px -1px #444;
        margin: 0 0 20px;
        width: 400px;
        figure{
            margin: 0;
        }
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
    .field-name-body{
        font-size: 18px;
        letter-spacing: 0.3px;
    }
    .field-name-field-until{
        background-color: #efefef;
        padding: 10px 15px;
        h2{
            font-size: 18px;
            letter-spacing: 0.7px;
            line-height: 1;
            margin: 0 10px 0 0;
        }
        .field-items{
            font-size: 18px;
            letter-spacing: 0.7px;
            line-height: 1;
            margin: 0 10px 0 0;
        }
    }
}
.node-nasha-rabota{
    .field-name-field-rabota-oblojka{
        box-shadow: 0 1px 3px -1px #444;
        margin: 0 0 20px;
        width: 360px;
        figure{
            margin: 0;
        }
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
    .field-name-body{
        font-size: 18px;
    }
    .field-name-field-image-work{
        margin: 30px 0;
        figure{
            background-color: #999;
            display: inline-block;
            margin: 0 10px 10px 0;
            vertical-align: top;
            width: 200px;
        }
        img{
            display: block;
            height: auto;
            width: 100%;
            &:hover{
                opacity: 0.9;
            }
        }
    }
}
.node-article{
    .field-name-field-image-article{
        box-shadow: 0 1px 3px -1px #444;
        margin: 0 0 20px;
        width: 400px;
        figure{
            margin: 0;
        }
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
    .field-name-body{
        font-size: 18px;
        letter-spacing: 0.3px;
    }
    .field-name-field-rubrika{
        ul{
            list-style: none;
            margin: 0 0 20px;
            padding: 0;
            li{
                display: inline;
            }
        }
        a{
            background-color: #efefef;
            color: #999;
            font-size: 18px;
            letter-spacing: 0.5px;
            margin: 0 5px 0 0;
            padding: 5px 20px;
            text-decoration: none;
            &:hover{
                background-color: #afb42b;
                color: #fff;
            }
        }
    }
}
.node-albom{
    .field-name-field-image-oblojka{
        box-shadow: 0 1px 3px -1px #444;
        margin: 0 0 20px;
        width: 360px;
        figure{
            margin: 0;
        }
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
    .field-name-body{
        font-size: 18px;
    }
    .field-name-field-image-albom{
        margin: 30px 0;
        figure{
            background-color: #fff;
            display: inline-block;
            margin: 0 10px 10px 0;
            vertical-align: top;
            width: 200px;
        }
        img{
            display: block;
            height: auto;
            opacity: 0.8;
            width: 100%;
            &:hover{
                opacity: 1;
            }
        }
    }
}
.page-node-407 #block-system-main .field-name-body{
    h2{
        font-size: 28px;
        letter-spacing: 0.7px;
        line-height: 1.2;
        margin: 0 0 20px;
    }
    p{
        font-size: 18px;
        margin: 0 0 20px;
    }
    img{
        box-shadow: 0 1px 3px -1px #444;
        height: auto !important;
        margin: 0 30px 20px 0;
        max-width: 300px !important;
    }
}
.page-node-408 #block-system-main .field-name-body{
    table{
        margin: 0;
    }
    tbody{
        border: none;
    }
    td{
        color: #777;
        font-size: 18px;
        letter-spacing: 0.7px;
        line-height: 1.3;
        padding-bottom: 30px !important;
        &:nth-child(1){
            text-align: center;
        }
        &:nth-child(2){
            padding: 0 0 0 40px;
        }
        a{
            border-bottom: 1px dashed;
            color: #afb42b;
            font-family: Open Sans;
            font-size: 28px;
            font-weight: 100;
            letter-spacing: 2px;
            line-height: 1 !important;
            opacity: 1;
            text-decoration: none;
            &:hover{
                border-bottom: 1px dashed transparent;
            }
        }
    }
    p{
        margin: 0;
        img{
            opacity: 1;
        }
        strong{
            color: #777;
            font-family: Open Sans;
            font-size: 40px;
            font-weight: 100;
            letter-spacing: 1px;
            line-height: 1;
            text-transform: uppercase;
        }
    }
}
#block-webform-client-block-1282{
    padding: 0px 20px;
    display: inline-block;
    margin: 0px 50px;
    vertical-align: top;
    width: 290px;
    h2{
        color: #fff;
        font-family: Open Sans;
        font-size: 26px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 0 20px;
    }
    .form-item{
        margin: 0 0 7px;
    }
    .form-type-checkbox{
        color: #fff;
    }
    input[type="text"],
    input[type="email"] {
        border: 1px solid #fff;
        font-family: PT Sans;
        font-size: 14px;
        letter-spacing: 0.7px;
        line-height: 1;
        padding: 10px 15px;
        width: 89%;
        background: transparent;
        color: #fff;
    }
    textarea{
        border: 1px solid #fff;
        font-family: PT Sans;
        font-size: 14px;
        height: 80px;
        letter-spacing: 0.5px;
        line-height: 1;
        padding: 7px 15px;
        width: 100%;
        background: transparent;
        color: #fff;
        resize: none;
    }
    .form-actions{
        input{
            background-color: #fff;
            border: medium none;
            color: #2d2d2d;
            cursor: pointer;
            font-family: PT Sans;
            font-size: 16px;
            letter-spacing: 0.7px;
            line-height: 1;
            margin: 0 !important;
            padding: 14px 30px;
            text-decoration: none;
            &:hover{
                background-color: #ed3326;
                color: #fff;
            }
        }
    }
}
.node-type-webform .node-webform{
    background-color: #efefef;
    box-shadow: 0 1px 3px -1px #777;
    padding: 10px 20px;
    .form-item{
        margin: 0 0 5px;
    }
    p{
        font-family: Open Sans;
        font-size: 16px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1.5;
        margin: 10px 0 20px;
    }
    input[type="text"],
    input[type="email"] {
        background-color: #fff;
        border: 1px solid #ccc;
        font-family: PT Sans;
        font-size: 18px;
        letter-spacing: 0.7px;
        line-height: 1;
        padding: 15px;
        text-transform: uppercase;
        width: 782px;
    }
    textarea{
        background-color: #fff;
        border: 1px solid #ccc;
        font-family: PT Sans;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 1;
        padding: 10px 15px;
        width: 100%;
    }
    .form-actions{
        input{
            background-color: #afb42b;
            border: medium none;
            border-radius: 0;
            color: #fff;
            cursor: pointer;
            font-family: Open Sans;
            font-size: 20px;
            font-weight: 100;
            letter-spacing: 0.7px;
            line-height: 1;
            padding: 10px 40px;
            &:hover{
                box-shadow: 0 3px 3px -3px #000;
                opacity: 0.8;
            }
        }
    }
}
#block-block-49{
    background-color: #efefef;
    height: 450px;
    margin: 0 0 -40px;
}

.not-front.page-node-1280,
.not-front.page-node-1281{
    h1{
        color: #cd2929;
        font-family: Open Sans;
        font-size: 150px;
        font-weight: 100;
        letter-spacing: 2px;
        line-height: 1 !important;
        margin: 40px 0 0;
        text-decoration: none;
        text-transform: uppercase;
    }
    .field-name-body{
        h2{
            font-size: 40px;
            letter-spacing: 2px;
            line-height: 1;
            margin: 10px 0 20px;
        }
        p{
            font-size: 18px;
            letter-spacing: 0.7px;
            margin: 0 0 20px;
        }
        a{
            color: #cd2929;
            &:hover{
                text-decoration: none;
            }
        }
    }
}
#block-system-main .view-nashi-klienty{
    .views-row{
        display: inline-block;
        margin: 0 10px 20px 0;
        width: 200px;
    }
    .views-field-field-image-logo-klient{
        display: table-cell;
        height: 140px;
        vertical-align: middle;
        width: 180px;
        img{
            display: block;
            height: auto;
            margin: 0 auto;
            max-height: 140px;
            max-width: 120px;
        }
    }
    .views-field-title{
        color: #999;
        font-size: 14px;
        letter-spacing: 0.3px;
        margin: 0;
        text-align: center;
    }
}
#block-system-main .view-nashi-partnery{
    .views-row{
        display: inline-block;
        margin: 0 10px 20px 0;
        width: 200px;
    }
    .views-field-field-image-logo-partner{
        display: table-cell;
        height: 140px;
        vertical-align: middle;
        width: 180px;
        img{
            display: block;
            height: auto;
            margin: 0 auto;
            max-height: 140px;
            max-width: 120px;
        }
    }
    .views-field-title{
        color: #999;
        font-size: 14px;
        letter-spacing: 0.3px;
        margin: 0;
        text-align: center;
    }
}
#block-system-main .view-otzyvy-personal{
    .views-row{
        background-color: #efefef;
        box-shadow: 0 1px 3px -1px #777;
        margin: 0 0 30px;
        min-height: 200px;
        padding: 20px 20px 20px 240px;
    }
    .views-field-field-image-otzyvfoto{
        border-radius: 150px;
        margin: 0 0 0 -220px;
        overflow: hidden;
        position: absolute;
        width: 200px;
        img{
            display: block;
            height: auto;
            margin: 0 auto;
            width: 100%;
        }
    }
    .views-field-title{
        font-size: 28px;
        font-weight: bold;
        letter-spacing: 0.5px;
        line-height: 1.3;
    }
    .views-field-field-image-doljnost{
        color: #999;
        font-size: 18px;
        letter-spacing: 0.7px;
        line-height: 1.3;
    }
    .views-field-body{
        background-image: url("../images/elements/citata.png");
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: 50px auto;
        color: #444;
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 1.7;
        margin: 20px 0 0;
        padding: 0 0 0 70px;
    }
}
#block-system-main .view-blagodarnosti{
    .views-row{
        display: inline-block;
        margin: 0 4% 20px 0;
        width: 20%;
    }
    .views-field-field-image-otzyv{
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
}
#block-system-main .view-search{
    .view-header{
        color: #999;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 0 30px;
    }
    .views-row{
        border-bottom: 1px solid #ccc;
        margin: 0 0 20px;
        padding: 0 0 20px;
        .views-field-title{
            a{
                color: #000;
                font-size: 24px;
                font-weight: bold;
                letter-spacing: 0.5px;
                line-height: 1.3;
                margin: 0 0 10px;
                &:hover{
                    color: #afb42b;
                    text-decoration: none;
                }
            }
        }
        .views-field-body{
            letter-spacing: 0.5px;
            line-height: 1.5;
            a{
                color: #afb42b;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
    .view-empty{
        p{
            color: #aaa;
            font-family: Open Sans;
            font-size: 30px;
            font-weight: 100;
            letter-spacing: 2px;
            line-height: 1.3;
            margin: 0;
            text-transform: uppercase;
        }
    }
}
/* End of pages

/* Footer */
#footer{
    background-color: #232323;
    .region{
        margin: 0 auto;
        padding: 70px 0 20px;
        width: 1140px;
    }
}
#block-block-46{
    display: inline-block;
    vertical-align: top;
    width: 350px;
    h2{
        line-height: 1;
        margin: -10px 0 5px;
        a{
            color: #fff;
            font-family: Open Sans;
            font-size: 40px;
            font-weight: 700;
            letter-spacing: 2px;
            line-height: 1 !important;
            margin: 0;
            text-decoration: none;
        }
    }
    h3{
        line-height: 1;
        margin: 0 0 20px;
        a{
            color: #fff;
            font-family: Open Sans;
            font-size: 20px;
            font-weight: 100;
            letter-spacing: 1.5px;
            line-height: 1;
            margin: 0;
            text-decoration: none;
        }
    }
    p{
        color: #fff;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 100;

        line-height: 1.7;
        margin: 0;
    }
}
#block-superfish-2{
    display: inline-block;
    float: right;
    vertical-align: top;
    width: 320px;
    h2{
        color: #fff;
        font-family: Open Sans;
        font-size: 40px;
        font-weight: 100;
        letter-spacing: 2px;
        line-height: 1.2;
        margin: 0 0 25px;
        opacity: 1;
        text-transform: uppercase;
    }
}
#superfish-2{
    width: 100%;
    li{
        border-bottom: 1px solid #777;
        float: left;
        margin: 0 5% 10px 0;
        width: 45%;
        a{
            color: #fff;
            display: block;
            font-size: 16px;
            letter-spacing: 0.7px;
            line-height: 1;
            padding: 0 0 5px;
            text-decoration: none;
            &:hover{
                color: #ed3326;
            }
        }
    }
}
#block-block-47{
    display: inline-block;
    margin: 0 0 0 50px;
    vertical-align: top;
    width: 300px;
    .block-content{
        .img_foot_phone{
            float: left;
            margin-top: 15px;
            margin-right: 15px;
        }
        .img_foot_mail{
            float: left;
            margin-top: 8px;
            margin-right: 15px;
        }

    }
    h2{
        color: #fff;
        font-family: Open Sans;
        font-size: 26px;
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 0 20px;
    }
    p{
        color: #fff;
        font-size: 16px;
        letter-spacing: 0.6px;
        line-height: 1.5;
        margin: 0px 40px 10px;
        strong{
            color: #fff;
            font-family: Open Sans;
            font-size: 22px;
            font-weight: 100;
            line-height: 1;
        }
        a{
            border-bottom: 1px solid;
            color: #fff;
            text-decoration: none;
            &:hover{
                border-bottom: 1px solid transparent;
                color: #ed3326;
            }
        }
    }
    .socseti{
        margin: 30px 0 0;
        & > div{
            display: inline-block;
            margin: 0;
            a{
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 25px auto;
                display: block;
                height: 40px;
                text-indent: -9999px;
                width: 40px;
            }
        }
        .set1 a{
            background-image: url("../images/icons/facebook.png");
            background-size: 100%;
            &:hover{
                background-image: url("../images/icons/facebook-white.png");
                background-size: 100%;
            }
        }
        .set4 a{
            background-image: url("../images/icons/twitter.png");
            background-size: 100%;
            &:hover{
                background-image: url("../images/icons/twitter-white.png");
                background-size: 100%;
            }
        }
        .set5 a{
            background-image: url("../images/icons/insta.png");
            background-size: 100%;
            &:hover{
                background-image: url("../images/icons/insta-white.png");
                background-size: 100%;
            }
        }
    }
}
#block-block-48{
    margin: 20px 0;
    table{
        width: 100%;
    }
    tbody{
        border: none;
    }
    td{
        color: #fff;
        font-family: Open Sans;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        a{
            border-bottom: 1px solid;
            color: #cd2929;
            font-weight: 400;
            text-decoration: none;
            &:hover{
                border-bottom: 1px solid transparent;
            }
        }
    }
}
/* End of footer */


/* Elements */
.breadcrumb {
    font-family: Open Sans;
    font-size: 16px;
    letter-spacing: 0.7px;
    line-height: 1;
    margin: 0 auto -120px;
    padding: 140px 0 0;
    width: 1140px;
    a{
        color: #777;
        &:hover{
            text-decoration: none;
        }
    }
    .last{
        a{
            color: #cd2929;
        }
    }
}
table{
    max-width: 100%;
}
.pager-load-more{
    margin: 30px 0 10px !important;
    li{
        margin: 0 !important;
        a{
            background-color: #cd2929;
            border: medium none;
            color: #fff;
            cursor: pointer;
            font-family: Open Sans;
            font-size: 16px;
            font-weight: 100;
            letter-spacing: 0.5px;
            line-height: 40px;
            margin: 0;
            padding: 12px 35px;
            text-decoration: none;
            text-transform: uppercase;
            &:hover{
                box-shadow: 0 1px 3px -1px #000;
            }
        }
    }
}
.pager-load-more-empty{
    li{
        background-color: #efefef;
        color: #999;
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 1;
        padding: 10px 40px !important;
        text-transform: lowercase;
    }
}
.progress-disabled {
    display: none;
}
.ajax-progress .throbber {
    background-image: url("../images/elements/preloader01.gif");
    background-position: center center;
    background-repeat: no-repeat;
    height: 70px;
    width: 70px;
}
/* Popup notice*/
#jGrowl {
    left: 0;
    margin: 0;
    position: fixed;
    top: 100px;
    width: 100%;
    .jGrowl-notification {
        background: #fff;
        border: none !important;
        border-radius: 0;
        box-shadow: 0 3px 3px 0 #777;
        color: #000;
        font-family: PT Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: normal;
        margin: 0 auto 10px;
        opacity: 0.97;
        padding: 30px 70px 30px 40px;
        width: 500px;
        .jGrowl-close {
            color: #777;
            font-family: PT Sans;
            font-size: 50px;
            font-weight: normal;
            height: 60px;
            margin: -40px -50px 0 0;
            &:hover{
                color: #222;
            }
        }
    }
    .jGrowl-closer {
        background-color: #ffdd2e;
        border: 1px solid #ccc !important;
        border-radius: 3px;
        box-shadow: 0 3px 3px 0 #777;
        color: #222;
        font-family: PT Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: bold;
        margin: 10px auto;
        opacity: 0.97;
        padding: 12px 50px 10px;
        width: 500px;
        &:hover {
            background-color: #ffcd33;
        }
    }
}
/* End of popup*/
/* Login and Registration */
.page-user{
    #main-content{
        padding: 0 0 100px;
    }
    #block-system-main{
        margin: 0;
        width: 100%;
    }
    #user-login{
        input[type="text"], input[type="password"] {
            font-size: 16px;
            line-height: 32px;
            padding: 7px 2%;
            width: 70%;
        }
    }
    #user-register-form{
        input[type="text"]{
            font-size: 18px;
            line-height: 32px;
            padding: 7px 2%;
            width: 70%;
        }
        input[type="password"]{
            font-size: 18px;
            line-height: 32px;
            padding: 7px 2%;
            width: 70%;
        }
    }
    #user-pass{
        input[type="text"]{
            font-size: 18px;
            line-height: 32px;
            padding: 7px 2%;
            width: 70%;
        }
    }
    #edit-actions{
        input{
            background-color: #ed3326;
            border: 2px solid #ed3326;
            color: #fff;
            cursor: pointer;
            display: block;
            font-family: PT Sans;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.7px;
            line-height: 1;
            padding: 12px 35px;
            text-decoration: none;
            text-transform: uppercase;
            &:hover{
                background-color: #fff;
                border: 2px solid #fff;
                color: #ed3326;
            }
        }
    }
}
/* End of login and registration */
#tasks{
    ul.primary{
        border-bottom: 1px solid #ccc;
        line-height: 35px;
        padding: 0;
        li{
            margin: 0;
            a{
                background-color: #efefef;
                border: medium none;
                color: #232323;
                font-family: PT Sans;
                font-size: 14px;
                margin: 0;
                padding: 8px 25px;
                &:hover{
                    background-color: #ddd;
                }
            }
            &.active{
                margin: 0;
                a{
                    background-color: #777;
                    color: #fff;
                    margin: 0;
                    padding: 12px 30px 9px;
                }
            }
        }
    }
}
/* End of elements */

/* About */
.page-node-1312{
    #columns{
        margin: auto;
        width: auto;
    }
    #main-content-header,
    #block-system-main{
        margin: 0 auto;
        width: 1140px;
    }
    .node-content{
        margin: 0;
    }
    #block-system-main .field-name-body{
        p{
            font-family: Open Sans;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.5px;
            line-height: 1.7;
            margin: 0 0 10px;
            opacity: 1;
        }
        img{
            margin: 0 0 20px 40px !important;
        }
    }
}
#block-views-rukovodstvo-block{
    padding: 50px 0;
    margin: 0 auto;
    width: 1140px;
    h2{
        font-family: "Exo 2";
        font-size: 30px;
        letter-spacing: 0.5px;
        line-height: 1;
        margin: 0 0 40px;
        &::after{
            background-color: #dc3326;
            content: "";
            display: block;
            height: 4px;
            margin: 10px 0 0;
            width: 190px;
        }
    }
    .slick-slider .slick-list{
        padding: 0 !important;
        .slick-track{
            padding: 0;
        }
    }
    .slick__slide{
        opacity: 1;
        .slide__content{
            background-color: transparent;
            border: none;
            border-radius: 0;
            height: 350px;
            margin: 0;
            &:hover{
                background-color: #fff;
                box-shadow: none;
            }
        }
        .views-field-title{
            padding: 10px 20px 5px;
            text-align: center;
            a{
                border-bottom: 1px solid #000;
                color: #000;
                font-family: Open Sans;
                font-weight: 600;
                letter-spacing: 0.5px;
                text-decoration: none;
                &:hover{
                    border-color: transparent;
                }
            }
        }
        .views-field-body{
            font-size: 14px;
            letter-spacing: 0.5px;
            padding: 0 20px;
            text-align: center;
        }
    }    
    .slick__arrow{
        opacity: 1;
        button{
            background-color: #444;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 40% auto;
            border-radius: 0;
            height: 80px;
            text-indent: -9999px;
            width: 40px;
            &:hover{
                background-color: #dc3326;
            }
        }
        .slick-prev{
            background-image: url("../images/elements/arrow-left.png");
        }
        .slick-next{
            background-image: url("../images/elements/arrow-right.png");
        }
    }
}
#block-block-59{
    background-color: #fff;
    padding: 50px 0;    
    h2{
        font-family: "Exo 2";
        font-size: 30px;
        letter-spacing: 0.5px;
        line-height: 1;
        margin: 0 auto 40px;
        width: 1140px;
        &::after{
            background-color: #dc3326;
            content: "";
            display: block;
            height: 4px;
            margin: 10px 0 0;
            width: 520px;
        }
    }
    .content{
        margin: 0 auto;
        width: 1140px;
    }
    .block{
        display: inline-block;
        margin: 0 30px 30px 0;
        vertical-align: top;
        width: 550px;
        &:nth-child(even){
            margin-right: 0;
        }
    }
    .number{
        border: 2px solid #dc3326;
        border-radius: 40px;
        display: inline-block;
        font-family: "Exo 2";
        font-size: 20px;
        line-height: 1;
        margin: 0 10px 10px 0;
        padding: 15px 0;
        text-align: center;
        vertical-align: middle;
        width: 50px;
    }
    .title{
        display: inline-block;
        font-family: Open Sans;
        font-weight: 700;
        letter-spacing: 0.5px;
        line-height: 1;
        margin: 0 0 10px;
        vertical-align: middle;
    }
    .text{
        color: #444;
        font-size: 14px;
        letter-spacing: 0.3px;
    }
}
#block-views-istoriya-block{
    padding: 50px 0 70px;
    margin: 0 auto;
    width: 1140px;
    h2{
        font-family: "Exo 2";
        font-size: 30px;
        letter-spacing: 0.5px;
        line-height: 1;
        margin: 0 0 20px;
        &::after{
            background-color: #dc3326;
            content: "";
            display: block;
            height: 4px;
            margin: 10px 0 0;
            width: 125px;
        }
    }
    .slick-slider .slick-list{
        padding: 0 !important;
        .slick-track{
            padding: 0;
        }
    }
    .slick__slide{
        opacity: 1;
        .slide__content{
            background-color: transparent;
            border: none;
            border-radius: 0;
            height: 350px;
            margin: 0;
            padding: 40px 20px;
            &:hover{
                background-color: #fff;
                box-shadow: none;
            }
        }
        .views-field-title{
            font-family: "Exo 2";
            font-size: 50px;
            span {
                border-bottom: 1px solid #444;
            }
        }
        .views-field-body{
            font-size: 14px;
            letter-spacing: 0.5px;
        }
    }
    .slick__arrow{
        opacity: 1;
        button{
            background-color: #444;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 40% auto;
            border-radius: 0;
            height: 80px;
            text-indent: -9999px;
            width: 40px;
            &:hover{
                background-color: #dc3326;
            }
        }
        .slick-prev{
            background-image: url("../images/elements/arrow-left.png");
        }
        .slick-next{
            background-image: url("../images/elements/arrow-right.png");
        }
    }
}
#block-views-partnery-block{
    background-color: #fff;
    padding: 70px 0;    
    h2{
        font-family: "Exo 2";
        font-size: 30px;
        letter-spacing: 0.5px;
        line-height: 1;
        margin: 0 auto 10px;
        width: 1140px;
        &::after{
            background-color: #dc3326;
            content: "";
            display: block;
            height: 4px;
            margin: 10px 0 0;
            width: 390px;
        }
    }
    .view{
        margin: 0 auto;
        text-align: center;
        width: 1140px;
    }
    .views-row{
        display: inline-block;
        vertical-align: middle;
        width: 19%;
    }
    .views-field-field-izobrazhenie-partnery{
        padding: 30px;
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
}
#block-views-eko-kampus-block{
    background-attachment: fixed;
    background-image: url("../images/bg-58.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0 70px;   
    h2{
        color: #fff;
        font-family: "Exo 2";
        font-size: 30px;
        letter-spacing: 0.5px;
        line-height: 1;
        margin: 0 auto 40px;
        width: 1140px;
        &::after{
            background-color: #dc3326;
            content: "";
            display: block;
            height: 4px;
            margin: 10px 0 0;
            width: 175px;
        }
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .view-header{
        color: #fff;
        display: inline-block;
        vertical-align: top;
        width: 600px;
        .title{
            font-family: Open Sans;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 0.5px;
            margin: 0 0 10px;
            opacity: 1;
        }
        p{
            font-family: Open Sans;
            font-weight: 400;
            letter-spacing: 1px;
            line-height: 1.7;
            margin: 0 0 10px;
        }
    }
    .view-content{
        display: inline-block;
        float: right;
        width: 480px;
    }
    .views-row{
        position: absolute;
        &:nth-child(1){
            margin: 0;
        }
        &:nth-child(2){
            margin: 50px 0 0 70px;
        }
        &:nth-child(3){
            margin: 100px 0 0 150px;
        }
        &:nth-child(4){
            margin: 150px 0 0 230px;
        }
    }
    .views-field-field-izobrazhenie-ek{
        width: 200px;
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
}
/* End of about */

.node-type-programma{

}

/* Programms */
.page-programmy{
    #columns{
        margin: auto;
        width: auto;
    }
    h1{
        margin: 30px auto 20px;
        width: 1140px;
    }
    #block-system-main{
        margin: 0 auto;
        width: 1140px;
    }
}
#block-system-main .view-programmy{
    margin: 30px 0 100px;
    .view-header{
        color: #232323;
        font-family: Open Sans;
        font-weight: 400;
        letter-spacing: 0.7px;
        line-height: 1.7;
        margin: 0 0 40px;
        p{
            margin: 0;
        }
    }
    .views-row{
        height: 190px;
        margin: 0 0 40px;
        padding: 0 20px 0 310px;
        &.views-row-1{
            background-color: #ed6a47;
        }
        &.views-row-2{
            background-color: #faae53;
        }
        &.views-row-3{
            background-color: #48c4b7;
        }
        &.views-row-4{
            background-color: #ced665;
        }
    }
    .views-field-field-izobrazhenie-programma{
        margin: 0 0 0 -310px;
        position: absolute;
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
    .views-field-title{
        padding: 20px 0 0;
        a{
            border-bottom: 1px solid #fff;
            color: #fff;
            font-family: "Exo 2";
            font-size: 24px;
            font-weight: 500;
            letter-spacing: 0.5px;
            line-height: 1;
            text-decoration: none;
            &:hover{
                border-color: transparent;
            }
        }
    }
    .views-field-body {
        color: #fff;
        font-family: Open Sans;
        font-size: 16px;
        font-weight: 100;
        letter-spacing: 0.5px;
    }
    .views-field-nid{
        margin: 25px 0 0;
        a{
            background-color: #fff;
            color: #2d2d2d;
            font-family: PT Sans;
            font-size: 14px;
            letter-spacing: 0.7px;
            line-height: 1;
            margin: 0;
            padding: 10px 30px;
            text-decoration: none;
            &:hover{
                background-color: #232323;
                color: #fff;
            }
        }
    }
}
/* End of programms */

/* Seminars */
#block-views-seminary-block{
    background-attachment: fixed;
    background-image: url("../images/bg-58.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 75px 0 -70px;
    padding: 70px 0;
    h2{
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        border-top: 1px solid rgba(255, 255, 255, 0.4);
        color: #fff;
        font-family: Open Sans;
        font-size: 30px;
        font-weight: 600;
        line-height: 1;
        margin: 0 auto 70px;
        padding: 10px 0 10px 160px;
        text-transform: uppercase;
        width: 980px;
        &::before{
            border: 5px solid #cd2929;
            border-radius: 100px;
            color: #cd2929;
            content: "\2193";
            display: block;
            font-size: 75px;
            height: 100px;
            line-height: 1;
            margin: -45px 0 0 -140px;
            position: absolute;
            text-align: center;
            width: 100px;
        }
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .views-row{
        min-height: 240px;
        padding: 0 0 0 650px;
    }
    .views-field-field-izobrazhenie-seminar{
        margin: 0 0 0 -650px;
        position: absolute;
        width: 300px;
        .field-content {
            height: 250px;
        }
        img{
            display: block;
            height: auto;
            width: auto;
            max-height: 100%;
            max-width: 100%;
            margin: 0 auto;
        }
    }
    .views-field-field-data-seminar{
        color: #fff;
        font-family: "Exo 2";
        font-size: 38px;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 1;
        margin: 0 0 0 -320px;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        width: 290px;
    }
    .views-field-field-prodolzhitelnost{
        color: #fff;
        font-family: "Exo 2";
        font-size: 40px;
        line-height: 1;
        margin: 50px 0 0 -320px;
        position: absolute;
        text-align: center;
        width: 290px;
    }
    .views-field-title{
        color: #fff;
        font-family: Open Sans;
        font-size: 30px;
        font-weight: 800;
        letter-spacing: 1px;
        line-height: 1.3;
        margin: 0 0 10px;
    }
    .views-field-body{
        color: #fff;
        font-family: Open Sans;
        font-size: 16px;
        font-weight: 100;
        letter-spacing: 0.7px;
        line-height: 1.7;
        p{
            margin: 0 0 10px;
        }
    }
    .views-field-nid{
        margin: 30px 0 0;
        a{
            background-color: #ed3326;
            border: 2px solid #ed3326;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.7px;
            line-height: 4;
            padding: 15px 30px;
            text-decoration: none;
            text-transform: uppercase;
            &:hover{
                background-color: #fff;
                border: 2px solid #fff;
                color: #ed3326;
            }
        }
    }
}
#block-views-seminary-block-1{
    background-color: #fff;
    padding: 70px 0 100px;
    h2{
        font-family: "Exo 2";
        font-size: 30px;
        letter-spacing: 0.5px;
        line-height: 1;
        margin: 0 auto;
        width: 1140px;
        &::after {
            background-color: #dc3326;
            content: "";
            display: block;
            height: 4px;
            margin: 10px 0 0;
            width: 340px;
        }
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .slick__slider{
        .slick-list{
            padding: 0 !important;
        }
        .slide__content{
            background-color: transparent;
            border: medium none;
            border-radius: 0;
            margin: 0;
            min-height: 380px;
            padding: 40px 30px;
            transition: all 0s ease 0s;
            &:hover{
                background-color: #fff;
                box-shadow: none;
            }
        }
        .views-field-field-data-seminar{
            font-family: "Exo 2";
            font-size: 40px;
            letter-spacing: 0px;
            line-height: 1;
            margin: 0 0 20px;
            span{
                border-bottom: 1px solid #000;
            }
        }
        .views-field-title{
            font-family: Open Sans;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.5px;
            line-height: 1.3;
            margin: 0 0 20px;
        }
        .views-field-body{
            font-family: Open Sans;
            font-size: 14px;
            letter-spacing: 0.5px;
            line-height: 1.7;
            margin: 0 0 20px;
            p{
                margin: 0;
            }
        }
        .views-field-nid{
            a{
                background-color: #cd2929;
                border: 1px solid #cd2929;
                color: #fff;
                font-size: 14px;
                letter-spacing: 0.7px;
                line-height: 1;
                padding: 7px 20px;
                text-decoration: none;
                &:hover{
                    background-color: #fff;
                    color: #cd2929;
                }
            }
        }
    }   
    .slick__arrow{
        opacity: 1;
        button{
            background-color: #444;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 40% auto;
            border-radius: 0;
            height: 80px;
            text-indent: -9999px;
            width: 40px;
            &:hover{
                background-color: #dc3326;
            }
        }
        .slick-prev{
            background-image: url("../images/elements/arrow-left.png");
        }
        .slick-next{
            background-image: url("../images/elements/arrow-right.png");
        }
    }
    .more-link{
        text-align: center;
        a{
            background-color: #cd2929;
            border: medium none;
            color: #fff;
            cursor: pointer;
            font-family: Open Sans;
            font-size: 16px;
            font-weight: 100;
            letter-spacing: 0.5px;
            line-height: 40px;
            margin: 0;
            padding: 12px 35px;
            text-decoration: none;
            text-transform: uppercase;
            &:hover{
                box-shadow: 0 1px 3px -1px #000;
            }
        }
    }
}
#block-system-main .view-seminary{
    padding: 30px 0 100px;
    .views-row {
        background-color: #fff;
        display: inline-block !important;
        margin: 0 25px 35px 0;
        min-height: 600px;
        vertical-align: top;
        width: 360px;
        transition: 0.4s;
        &:nth-child(3n+3){
            margin-right: 0;
        }
        &:hover{
            box-shadow: 0 1px 7px -1px #444;
        }
    }
    .views-field-field-izobrazhenie-seminar{
        width: 100%;
        img{
            display: block;
            height: auto;
            width: auto;
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
        }
    }
    .views-field-field-prodolzhitelnost{
        font-family: "Exo 2";
        font-size: 22px;
        letter-spacing: 0.5px;
        line-height: 1;
        padding: 10px 20px;
    }
    .views-field-title{
        padding: 0 20px 10px;
        a{
            color: #000;
            display: block;
            font-family: Open Sans;
            font-size: 20px;
            font-weight: 700;
            line-height: 1.3;
            text-decoration: none;
            &:hover{
                color: #cd2929;
            }
        }
    }
    .views-field-body{
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 100;
        letter-spacing: 0.5px;
        line-height: 1.7;
        padding: 0 20px;
        p{
            margin: 0;
        }
    }
    .views-field-nid{
        padding: 30px 20px 10px;
        a{
            background-color: #cd2929;
            border: 1px solid #cd2929;
            color: #fff;
            font-size: 14px;
            letter-spacing: 0.7px;
            line-height: 1;
            padding: 7px 20px;
            text-decoration: none;
            &:hover{
                background-color: #fff;
                color: #cd2929;
            }
        }
    }
}
.node-type-seminar{
    .field-name-field-data-seminar{
        color: #cd2929;
        font-family: "Exo 2";
        font-size: 50px;
        letter-spacing: 1px;
        line-height: 1;
    }
    .field-name-field-prodolzhitelnost{
        font-family: "Exo 2";
        font-size: 30px;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 0 20px;
        padding: 10px 0;
        .field-item{
            border-bottom: 2px solid #000;
            display: inline;
        }
    }
}

/* End of seminars */

/* Novosti */
#block-system-main .view-novosti{
    margin: 30px 0 100px;
    h2{
        font-family: Open Sans;
        font-size: 44px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        width: 1140px;
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .views-row{
        background-color: #fff;
        border: medium none;
        display: inline-block !important;
        margin: 0 15px 17px 0;
        padding: 0;
        vertical-align: top;
        width: 270px;
        &:nth-child(4n+4){
            margin-right: 0;
        }
        &:hover{
            box-shadow: 0 1px 3px -1px #444;
        }
    }
    .views-field-field-izobrazhenie-novost{
        margin: 0 0 10px;
        overflow: hidden;
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
    .views-field-title{
        margin: 0 0 10px;
        padding: 0 15px;
        text-align: left;
        a{
            color: #000;
            display: block;
            font-family: Open Sans;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0.5px;
            line-height: 1.2;
            text-decoration: none;
            &:hover{
                color: #cd2929;
            }
        }
    }
    .views-field-created{
        color: #999;
        font-family: PT Sans;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1;
        margin: 0 0 10px;
        padding: 0 15px;
        text-align: left;
    }
    .views-field-body{
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1.5;
        margin: 0 0 10px;
        padding: 0 15px;
        text-align: left;
        p{
            margin: 0;
        }
    }
    .views-field-field-kategoriya-novost{
        padding: 0 15px;
        a{
            background-color: #e7e7e7;
            color: #444;
            display: table;
            font-size: 10px;
            font-weight: 100;
            letter-spacing: 0.3px;
            margin: 0 0 2px;
            opacity: 1;
            padding: 2px 5px;
            text-decoration: none;
            &:hover{
                background-color: #cd2929;
                color: #fff;
            }
        }
    }
    .views-field-nid{
        margin: 20px 0;
        padding: 0 15px;
        text-align: left;
        a{
            background-color: #cd2929;
            border: 1px solid #cd2929;
            color: #fff;
            font-size: 14px;
            letter-spacing: 0.7px;
            line-height: 1;
            padding: 7px 20px;
            text-decoration: none;
            span{
                margin: 0 0 0 10px;
            }
            &:hover{
                background-color: #fff;
                color: #cd2929;
            }
        }
    }
}
.node-type-novost{
    .field-name-field-kategoriya-novost ul{
        padding: 10px 0;
        li{
            display: inline;
            list-style: outside none none;
            margin: 0 5px 5px 0;
            a{
                background-color: #aaa;
                color: #fff;
                letter-spacing: 0.5px;
                line-height: 1;
                padding: 7px 15px;
                text-decoration: none;
                &:hover{
                    background-color: #cd2929;
                }
            }
        }
    }
}
/* End of novosti */

/* Blog */
#block-system-main .view-publikacii{
    margin: 30px 0 100px;
    h2{
        font-family: Open Sans;
        font-size: 44px;
        font-weight: 100;
        letter-spacing: 1px;
        line-height: 1;
        margin: 0 auto 40px;
        text-align: center;
        width: 1140px;
    }
    .view{
        margin: 0 auto;
        width: 1140px;
    }
    .views-row{
        background-color: #fff;
        border: medium none;
        display: inline-block !important;
        margin: 0 15px 17px 0;
        padding: 0;
        vertical-align: top;
        width: 270px;
        &:nth-child(4n+4){
            margin-right: 0;
        }
        &:hover{
            box-shadow: 0 1px 3px -1px #444;
        }
    }
    .views-field-field-izobrazhenie-publikacii{
        margin: 0 0 10px;
        overflow: hidden;
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
    .views-field-title{
        margin: 0 0 10px;
        padding: 0 15px;
        text-align: left;
        a{
            color: #000;
            display: block;
            font-family: Open Sans;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0.5px;
            line-height: 1.2;
            text-decoration: none;
            &:hover{
                color: #cd2929;
            }
        }
    }
    .views-field-body{
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1.5;
        margin: 0 0 10px;
        padding: 0 15px;
        text-align: left;
        p{
            margin: 0;
        }
    }
    .views-field-field-kategoriya-publikacii{
        padding: 0 15px;
        a{
            background-color: #e7e7e7;
            color: #444;
            display: table;
            font-size: 10px;
            font-weight: 100;
            letter-spacing: 0.3px;
            margin: 0 0 2px;
            opacity: 1;
            padding: 2px 5px;
            text-decoration: none;
            &:hover{
                background-color: #cd2929;
                color: #fff;
            }
        }
    }
    .views-field-nid{
        margin: 20px 0;
        padding: 0 15px;
        text-align: left;
        a{
            background-color: #cd2929;
            border: 1px solid #cd2929;
            color: #fff;
            font-size: 14px;
            letter-spacing: 0.7px;
            line-height: 1;
            padding: 7px 20px;
            text-decoration: none;
            span{
                margin: 0 0 0 10px;
            }
            &:hover{
                background-color: #fff;
                color: #cd2929;
            }
        }
    }
}
.node-type-publikaciya{
    .field-name-field-kategoriya-publikacii ul{
        padding: 10px 0;
        li{
            display: inline;
            list-style: outside none none;
            margin: 0 5px 5px 0;
            a{
                background-color: #aaa;
                color: #fff;
                letter-spacing: 0.5px;
                line-height: 1;
                padding: 7px 15px;
                text-decoration: none;
                &:hover{
                    background-color: #cd2929;
                }
            }
        }
    }
}
/* End of blog */

/* Questions */
.view-vopros-otvet{
    padding: 30px 0 100px;
    .views-row{
        background-color: #fff;
        margin: 0 0 30px;
        padding: 30px 20px 30px 400px;
    }
    .views-field-title{
        font-family: Open Sans;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.5px;
        line-height: 1.3;
        margin: 0 0 0 -380px;
        position: absolute;
        width: 360px;
    }
    .views-field-body{
        font-family: Open Sans;
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1.7;
    }
}
/* End of questions */

.node-1398{
    .node-content{
        margin: 20px 0 50px;
    }
    .field-name-body{
        p{
            background-color: #fff;
            box-shadow: 0 1px 3px -1px #000;
            margin: 0 0 15px;
            padding: 10px 0 10px 20px;
        }
    }
}
.node-prepodavatel{
    padding: 0 0 40px;
    .field-name-field-dolzhnost{
        font-family: Open Sans;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 1px;
        margin: 0 0 10px;
    }
}
.node-ot-pervogo-lica{
    .node-content{
        min-height: 320px;
        padding: 20px 0 0 240px;
    }
    .field-name-field-izobrazhenie-opl{
        margin: 0 0 0 -240px;
        position: absolute;
        figure{
            float: none;
            margin: 0;
        }
    }
    .field-name-field-familiya-i-imya{
        font-family: "Exo 2";
        font-size: 24px;
        font-weight: 700;
        letter-spacing: 0.7px;
        line-height: 1.1;
        margin: 250px 0 0 -240px;
        position: absolute;
        width: 200px;
    }
}
#block-system-main .view-ot-pervogo-lica{
    padding: 20px 0 80px;
    .views-row{
        background-color: #fff;
        border: medium none;
        display: inline-block !important;
        margin: 0 15px 17px 0;
        padding: 0;
        vertical-align: top;
        width: 270px;
        &:hover{
            box-shadow: 0 1px 3px -1px #444;
        }
        &:nth-child(4n+4) {
            margin-right: 0;
        }
    }
    .views-field-field-izobrazhenie-opl {
        margin: 0 0 10px;
        overflow: hidden;
        img{
            display: block;
            height: auto;
            width: 100%;
        }
    }
    .views-field-title {
        margin: 0 0 10px;
        padding: 0 15px;
        text-align: left;
        a{
            color: #000;
            display: block;
            font-family: Open Sans;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0.5px;
            line-height: 1.2;
            text-decoration: none;
            &:hover{
                color: #cd2929;
            }
        }
    }
    .views-field-field-familiya-i-imya{
        background-color: #ccc;
        color: #444;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
        margin: 0 0 10px;
        padding: 5px 15px;
    }
    .views-field-body {
        font-size: 14px;
        letter-spacing: 0.5px;
        line-height: 1.5;
        margin: 0 0 10px;
        padding: 0 15px;
        text-align: left;
        p{
            margin: 0;
        }
    }
    .views-field-nid {
        margin: 20px 0;
        padding: 0 15px;
        text-align: left;
        a {
            background-color: #cd2929;
            border: 1px solid #cd2929;
            color: #fff;
            font-size: 14px;
            letter-spacing: 0.7px;
            line-height: 1;
            padding: 7px 20px;
            text-decoration: none;
            &:hover{
                background-color: #fff;
                color: #cd2929;
            }
        }
    }
}

#block-views-prepodavateli-block-2{
    display:none;
}

#block-views-ot-pervogo-lica-block-1{
    display:none;
}

#skip-link{
    display:none;
}

#block-views-seminary-block-3{
    display:none; 
}

#block-views-rukovodstvo-block-1{
    display:none; 
}

#block-views-istoriya-block-1{
    display:none;
}



.not-front{
    .node-content{
        .field-name-body {
            .video-filter {
                width: 50%;
                text-align: center;
                margin: 0 auto;

                .video-filter {
                    margin: 20px 0!important;
                    width: 100%!important;
                }
            }
        }
    }

} 
.slick-initialized {
    .slick-slide {
        display: block;
        cursor: pointer;
    }
}
